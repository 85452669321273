import React from 'react';
// import { FaExclamationCircle } from 'react-icons/fa';
import { LuConstruction} from 'react-icons/lu';
const ServerErrorPage: React.FC<{ error: string }> = ({ error }) => (
  <div className="flex flex-col items-center justify-center h-screen">
    {/* <FaExclamationCircle className="text-red-500 text-6xl mb-4" /> */}
    <LuConstruction className="text-red-500 text-6xl mb-4 h-36 w-36" />
    <h1 className="text-2xl font-bold mb-2">Under  construction Bear with Us</h1>
    <p className="text-gray-600">For futher Details Contact IT</p>
  </div>
);

export default ServerErrorPage;
