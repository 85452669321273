import React, { useState } from 'react';

const RateButton = () => {
  const [totalRating, setTotalRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [isRated, setIsRated] = useState(false);

  const handleRate = (rate:number) => {
    if (!isRated) {
      setTotalRating(totalRating + rate);
      setRatingCount(ratingCount + 1);
      setIsRated(true);
    }
    else{
        setTotalRating(0);
      setRatingCount(0);
      setIsRated(false);
    }
  };

  const generateStarRating = (averageRating:number) => {
    const fullStars = Math.floor(averageRating);
    const halfStar = averageRating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    const stars = [];
    for (let i = 1; i <= fullStars; i++) {
      stars.push(
        <span key={i} className='text-2xl cursor-pointer' onClick={() => handleRate(i)}>★</span>
      );
    }
    if (halfStar) {
      stars.push(
        <span key={fullStars + 1} className='text-2xl cursor-pointer' onClick={() => handleRate(fullStars + 0.5)}>★</span>
      );
    }
    for (let i = 1; i <= emptyStars; i++) {
      stars.push(
        <span key={fullStars + halfStar + i} className='text-2xl cursor-pointer' onClick={() => handleRate(fullStars + halfStar + i)}>☆</span>
      );
    }
    return stars;
  };

  const averageRating = ratingCount > 0 ? totalRating / ratingCount : 0;
//   const ratingPercentage = (averageRating / 5) * 100;

  return (
    <div className='flex flex-col items-center mt-2'>
      <div className='flex'>
        {generateStarRating(averageRating)}
      </div>
    </div>
  );
};

export default RateButton;
