import React,{useState} from "react";
 function Settings(){
    const [roleOptions, setRoleOptions] = useState([
        'Admin', 'National',
      ]);
      const handleRoleOptions = (role:any) => {
        setRoleOptions(role);
        
      };
    return(
        <div className="p-8">
            <h2 className="text-black text-[25px] font-semibold font-['Goudy Old Style'] mb-6">Add Role</h2>
                <select
                id="role"
                value={roleOptions}
                onChange={(e)=>handleRoleOptions(e.target.value)}
                className=" h-16 w-11/12 bg-white rounded-lg border-2 border-black p-4"
                >
                {roleOptions.map((role, index)=>(
                <option key={index} value={role} className="w-6 relative">
                    {role}
                </option>
                ))}
                </select>
                <div className=' flex flex-row justify-end mr-24 mt-8'>
        <button
          type="button"
          
          className="bg-gray-300 text-black py-2 px-4 w-2/12 rounded-lg mr-4"
        >
          Cancel
        </button>
        <button
          type="button"
          
          className="bg-gray-300 text-black w-2/12 py-2 px-4  rounded-lg"
        >
          save
        </button>
        </div>
        </div>
    )
 }
 export default Settings