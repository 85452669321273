import React, { useRef, useState, useEffect } from 'react';
import { Empty, Modal,Pagination,notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from "react-icons/fa";
import Chart from 'react-google-charts';
import { useNavigate } from "react-router-dom";
import {useDarkMode} from '../../contextApis/DarkModeContext'
import { FadeLoader } from 'react-spinners';
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}
const SrEast: React.FC = () => {
  const [usersData, setUsersData] = useState<any[]>([]);
  const [totalRegionId2, setTotalRegionId2] = useState<number>(0);
  const storedUser = localStorage.getItem('user');
  const User = storedUser ? JSON.parse(storedUser) as User : null;
  const [nationList, setNationList] = useState<any[]>([]);
  const[currentPage,setCurrentPage]=useState<number>(1)
  const itemsPerPage=50
  const {darkMode}=useDarkMode()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const navigate = useNavigate();
  const {t}=useTranslation()
  const calculateNationalityCounts = () => {
    const nationalityCounts: { [key: number]: number } = {};
    usersData.forEach((user) => {
      if (user.nationality) {
        nationalityCounts[user.nationality] = (nationalityCounts[user.nationality] || 0) + 1;
      }
    });
    return nationalityCounts;
  };

  // Format nationality counts for Google Chart
  const formatChartData = (t: any) => {
    const nationalityCounts = calculateNationalityCounts();
    const chartData = [['Nationality', 'Number of People', { role: 'style' }]];
    const fetchedNationalities = usersData.map(user => user.nationality).filter(n => n); // Filter out null or undefined values
    const uniqueNationalities = Array.from(new Set(fetchedNationalities)); // Get unique nationalities
    
    uniqueNationalities.forEach((id) => {
      const nation = nationList.find(n => n.id === id);
      const count = fetchedNationalities.filter(n => n === id).length;
      let color = '';
      switch (id) {
        case 1:
          color = '#b87333'; // Brown
          break;
        case 2:
          color = '#3366cc'; // Blue
          break;
        case 3:
          color = '#dc3912'; // Red
          break;
        case 4:
          color = '#ff9900'; // Orange
          break;
        case 5:
          color = '#109618'; // Green
          break;
        default:
          color = '#3366cc'; // Black (default color)
          break;
      }
      chartData.push([t(nation?.name) || 'Unknown', count, color]);
    });
  
    return chartData;
  };
  
  // Assuming `countryData` is declared as follows:
  const countryData = formatChartData(t);
  const GetAllUsers = async (): Promise<any> => {
    try {
      const apiUrl = 'https://api.upfafrica.org/admin/user/list';
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: {},
          options: {
            select: [
              'id',
              'name',
              'position',
              'upfPosition',
              'regionId',
              'subRegionId',
              'nationality',
            ],
            "paginate": 800000
          },
          isCountOnly: false,
        }),
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed. ');
      }

      const userData = await response.json();

      console.log(userData.data); // Log the userData to check its structure

      if (Array.isArray(userData.data.data)) {
        setUsersData(
          userData?.data?.data?.filter((item: any) =>
            User?.userType === 8
              ? item?.subRegionId === 12
              : item?.subRegionId === User?.subRegionId
          )
        );
        
        console.log(userData.data.data); // Log the data here
      } else {
        console.error('Invalid user data format:', userData);
        throw new Error('Invalid user data format. ');
      }

      return userData;
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  };
  const deleteUser = async (id: any) => {
    try {
        const apiUrl = `https://api.upfafrica.org/admin/user/delete/${id}`;
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            console.error('Request failed:', response.status, response.statusText);
            throw new Error('Request failed.');
        }

        const responseData = await response.json();
        console.log('User deleted successfully:', responseData);

        // Display a success notification
        notification.open({
            message: 'User Deleted',
            description: 'The user has been deleted successfully.',
            duration: 3, // Duration in seconds
        });
     
        GetAllUsers();

        return responseData;
    } catch (error) {
        console.error('Error:', error);

        // Display an error notification
        notification.error({
            message: 'Error Deleting User',
            description: 'An error occurred while deleting the user.',
            duration: 3,
        });

        return error;
    }
};
  const showModal = (id: any) => {
    setUserIdToDelete(id);
    setIsModalVisible(true);
};
const handleEdit = (id: any) => {
  navigate(`/EditAmbassador/${id}`);
};

const handleDelete = async () => {
  try {
      await deleteUser(userIdToDelete);
      setIsModalVisible(false);
  } catch (error) {
      // Handle error
  }
};
  const getNationNameById = (id: any) => {
    if (!id || !Array.isArray(nationList) || nationList.length === 0) {
        return '';
    }
    const nation = nationList.find(n => n.id === id);
    return nation ? nation.name : '';
};
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
    const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                  "query": {},
                  "options": {
                      "select": [
                          "id",
                          "name",
                      ],
                      "paginate": 366
                  },
                  "isCountOnly": false
              }),
          });
          const nationData = await nationResponse.json();
          if (nationData?.data) {
              setNationList(nationData.data.data);
          } else {
              console.error('Invalid nation data:', nationData);
              throw new Error('Invalid nation data.');
          }
        }
        fetchData()
    GetAllUsers();
  }, []);

  useEffect(() => {
    const totalRegion2 = usersData.filter((user) => user.subRegionId ===12)
      .length;
    setTotalRegionId2(totalRegion2);
  }, [usersData]);
  const handleChartClick = (e: any) => {
    const chart = e.chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 0) return;
  
    const selectedItem = selection[0];
    const nationName = countryData[selectedItem.row + 1][0]; // Get the nation name from the chart data
    const nationId = nationList.find(nation => nation.name === nationName)?.id; // Find the nation ID based on the name
  
    if (nationId) {
      navigate(`/nation/${nationId}`); // Navigate to the nation's ID page
    }
  };
   
    const back=()=>{
      navigate(-1)
     }
  const handlePageChange=(page:number)=>{
    setCurrentPage(page)
  } 
  if (usersData.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
  }
  const paginatedData=usersData.slice((currentPage-1)*itemsPerPage,currentPage*itemsPerPage) 
      const handleNext = () => {
        navigate("/info-update/1");
      };
      return (
        <><div className="container mx-auto p-2">
           <button onClick={back} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
           <p className='text-xl font-semibold ml-3'>Back</p>
          </button>
            <div className="containerr rounded-lg overflow-hidden shadow-lg bg-white flex w-[95%] ml-5 ">
                <div className="px-6 py-4 w-full m-6 justify-center space-y-5 ">
                    <div className='flex gap-2 text-lg '>
                        <p>{t('Total')}</p>
                        <p className='text-xl font-semibold'>{totalRegionId2.toLocaleString()}</p>
                    </div>
                   
                </div>
            </div>
            <div className='p-4'>
            </div>
            <div className='w-[95%]  containerrr rounded-lg p-3 ml-4  overflow-hidden shadow-lg bg-white '>
              <p className='text-2xl font-semibold'>{t('Individuals Per Country')}</p>
            <Chart
            className={`rounded-lg${ darkMode ? '#fff' : '#000' }`}
  width={'95%'}
  height={'400px'}
  chartType="ColumnChart"
  loader={<div>Loading Chart</div>}
  data={countryData}
  options={{
    textStyle: { color: darkMode ? '#fff' : '#000' },
    chartArea: { width: '60%' },
    hAxis: {
      title: '',
      minValue: 0,
      textStyle: { color: darkMode ? '#fff' : '#000' }
    },
    vAxis: {
      title: '',
      textStyle: { color: darkMode ? '#fff' : '#000' }
    },
    backgroundColor: darkMode ? '#333' : 'white', // Set background color
    legend: 'none', // Hide legend
  }}
  chartEvents={[
    {
        eventName: 'select',
        callback: handleChartClick
    }
]}
/>
</div>
              <div className="containerr  rounded-lg overflow-hidden shadow-lg bg-white flex w-[95%] ml-5 mt-8 ">
               

                    <div className="px-6 py-4 w-full">
                        <div className="flex justify-between">
                            <p className='gap-2 text-lg font-bold'>{t('SR East')}</p>
                            <div className="flex flex-row justify-between mt-6">
      {User && (User?.userType === 2||User?.userType === 8)&& (
        <button
          onClick={handleNext}
          className="w-full h-10 mr-7 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr "
        >
          + Add
        </button>
      )}
      </div>
                        </div>
                        <table className="w-full">
                        <thead className='text-left'>
                            <tr>
                            <th className="py-2">{t('NO')}</th>
                                <th className="py-2 px-3">{t('Contact')}</th>
                                <th className="py-2">{t('Position')}</th>
                                <th className="py-2">{t('Nationality')}</th>
                                {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) &&<th className='py-2'>Action</th>}
                            </tr>
                        </thead>
                        {paginatedData?.length > 0 ? (
                            <tbody>
                                {paginatedData.map((user, index) => (
                                    <tr key={index}>
                                         <td className="py-2 px-1">{(currentPage-1)*itemsPerPage+index + 1}</td>
                                        <td className="py-2 px-3">{user.name}</td>
                                        <td className="py-2 ">{((user.position || user.upfPosition) && (user.position || user.upfPosition).split(' ').slice(0, 6).join(' '))}</td>
                                        <td className="py-2 w-1/4">{user.nationality && getNationNameById(user.nationality)}</td>
                                        {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && (
                                            <td className="py-2 flex flex-row">
                                                <FontAwesomeIcon
                                                    onClick={() => showModal(user?.id)}
                                                    className="cursor-pointer"
                                                    color="#FF0000"
                                                    icon={faTrash}
                                                />
                                                <CiEdit className="cursor-pointer ml-2 w-11" onClick={() => handleEdit(user?.id)} />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        ) : <Empty description="No Data Found" />}

                    </table>
                    <Pagination
                       current={currentPage}
                       pageSize={itemsPerPage}
                       total={usersData.length}
                       onChange={handlePageChange}
                       style={{ textAlign: 'center', marginTop: '20px' }}
                    />
                    <Modal
                        title="Delete User"
                        visible={isModalVisible}
                        onOk={handleDelete}
                        onCancel={() => setIsModalVisible(false)}
                        okText="Delete"
                        cancelText="Cancel"
                        okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
                    >
                        <p>Are you sure you want to delete this user?</p>
                    </Modal>
                    {/* <Pagination
    className="mt-4 float-right"
    current={currentPage}
    pageSize={pageSize}
    total={162}
    onChange={handlePageChange}
    showSizeChanger={false}
    showQuickJumper={true}
/> */}
                    </div>
                </div>
         

        </div>


        </>
    );
};

export default SrEast;
