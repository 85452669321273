import React, { useEffect, useState } from 'react';
import Sidebar from './sideBar';
import Navbar from './navbar';
import { MdDashboard } from "react-icons/md";
import { TiGroup } from "react-icons/ti";
import { BiWorld } from "react-icons/bi";
import { IoPerson } from "react-icons/io5";
import { FaRegImage } from "react-icons/fa6";
import { MdEventRepeat } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { MdConnectWithoutContact } from "react-icons/md";
import { BiSolidDonateHeart } from "react-icons/bi";
import { IoSettings } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {  useSummits } from '../../contextApis/SummitProvider'
interface User {
    userType: number;
    subRegionId:number;
    regionId:number;
    nationality:number;
  }


  const Dashboard = ({ children }:any) => {
    const { summitsCount } = useSummits();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [selectedMenu, setSelectedMenu]:any = useState(null);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const {t}=useTranslation()
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const navigate= useNavigate();
 
    const token = localStorage.getItem('token');
    useEffect(() => {
        // Check if the user is logged in (e.g., by checking the token)
        if (!token) {
            navigate('/login');
        } 
      }, []);
      


const menuItems: any[] = [
    ...(((User?.userType === 8)||(User?.userType === 2)||(User?.userType === 7)||(User?.userType === 6)||(User?.userType === 3)||(User?.userType === 5))? [{ icon: <MdDashboard className="hover:bg-green-500" />, text: t('Dashboard'), to: '/', colorClass: 'icon h2' }] : []),
    { icon: <TiGroup className="hover:bg-green-500" />, text: t('Association'), to: '/Association', colorClass: 'icon' },
    ...((User?.userType === 8)||(User?.userType === 3)||(User?.userType === 2)? [{ icon: <MdEventRepeat />, text: (<>Activity Approval <div className='rounded-full bg-red-500 h-6 w-6  text-red-900 flex justify-center ml-4  '><p >{summitsCount}</p></div></>), to: '/approval', colorClass: 'icon' }] : []),
    { icon: <BiWorld />, text: t('Activities'), to: '/Summits', colorClass: 'icon' },
    { icon: <MdConnectWithoutContact />, text: t('Contacts'), to: '/Contacts', colorClass: 'icon' },
    { icon: <IoPerson />, text: t('Ambassadors for Peace'), to: '/Ambassador-info', colorClass: 'icon' },
    { icon: <FaRegImage />, text: t('Leadership'), to: '/Leadership', colorClass: 'icon' },
    { icon: <FaCalendarAlt />, text: t('Calendar'), to: '/calendar', colorClass: 'icon' },
    { icon: <BiSolidDonateHeart />, text: t('Donation'), to: '/error', colorClass: 'icon' },
    { icon: <IoSettings />, text: 'Settings', to: '/error', colorClass: 'icon' },
];
    return (
        <div className="flex">
            <div className=' lg:block hidden'>
            <Sidebar setSelectedMenu={setSelectedMenu} isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} menuItems={menuItems}/>
            </div>
            <div className="flex flex-col lg:w-[100%]">
                <Navbar setSelectedMenu={setSelectedMenu} menuItems={menuItems} isSidebarOpen={isSidebarOpen}/>
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default Dashboard;
