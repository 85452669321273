import React, { useState } from "react";
import Input from "./InputDonation";
import { IoPerson } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import Mastercard from '../images/Mastercard.svg';
import { BsCalendar2MonthFill } from "react-icons/bs";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { LuConstruction } from "react-icons/lu";
import { CiDollar } from "react-icons/ci";
import Donations from './Donation.json';
import Visa from '../images/Visa Icon.svg';
import Construct from '../images/R.jpg';
import ReactEcharts from "echarts-for-react";
import monthlyDon from "./monthlydonations.json"
import { useNavigate } from "react-router-dom";
function Donation() {
  const navigate=useNavigate()
  const [card, setCard] = useState('');
  const [working, setWorking] = useState('false');
  const [donors, setDonors] = useState(Donations.donors);
  const handleSortTopDonors = () => {
    const sortedDonors = [...donors].sort((a, b) => b.totalDonation - a.totalDonation);
    setDonors(sortedDonors);
  };
  const handleSortLatestDonors = () => {
    const sortedDonors = [...donors].sort((a, b) => {
      // Convert dates to milliseconds
      const dateA = new Date(a.latestDonation?.date || 0).getTime();
      const dateB = new Date(b.latestDonation?.date || 0).getTime();
  
      // Compare dates
      return dateB - dateA;
    });
    
    // Return sorted donors
    return  setDonors(sortedDonors);
  };
  
  
   
  const admiReqByReqTypeData = {
    grid: {
      left: '0%',
      right: '0%',
      bottom: '20%',
      top: '10%',
      height:"90%",
      containLabel: true
    },
    xAxis: [
      {
        name: 'Request Type',
        nameLocation: 'center',
        nameTextStyle: {
          fontWeight: 'normal',
          fontSize: 18,
          color: '#00000',
          padding: [50, 0, 0, 0],
        },
        axisLine: {
          show: false,
        },
        type: 'category',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value:any) {
            return value;  // Keep the original label without splitting
          },
          textStyle: {
            whiteSpace: 'pre-wrap', // Allow wrapping within words
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
    ],
    
    
    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
        name: 'Admin Requests',
        nameLocation: 'center',
        nameTextStyle: {
          fontWeight: 'normal',
          fontSize: 18,
          color: '#00000',
          padding: [0, 0, 30, 0],
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: 'value',
      },
    ],
    series: [
      {
        itemStyle: {
          color: '#0e72b7', // Set the bar color
        },
        type: 'bar',
        data: monthlyDon.data.map(item => item.amount),
        label: {
          position: 'top',
          show: true,
        },
      },
    ],
  };
  return (
    <>
    <div className="flex flex-col p-4">
      <div className="w-3/12 mb-4  bg-white text-black p-4 containerr rounded-lg ml-6">
      </div>
      <div className=" h-48 bg-white text-black p-4 containerr rounded-lg">
      
        <div className="flex flex-row justify-between">
          <h2 className="ml-3 h-[15px] text-center font-bold font-['Helvetica'] leading-[15px">Payment Method</h2>
          <button
            className="rounded-lg bg-black text-white mr-3 px-4 py-2 text-center font-bold font-['Helvetica'] leading-[15px"
           
          >
            + Add a new card
          </button>
        </div>
      <div className="flex flex-row justify-around mt-9">
                <Input
            icon={Mastercard}
            placeholder="7812 2139 0823 XXXX"
            value={card}
            className=" w-full h-14 border-gray-600 border-2 rounded-lg pl-16"
            onChange={(e:any) => setCard(e.target.value)}
          />
                 <Input
            icon={Visa}
            placeholder="7813 2139 0823 XXXX"
            value={card}
            className="w-full h-14  border-gray-600 border-2 rounded-lg pl-16"
          />
            </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2  mt-6 gap-4">
        <div className=" mb-4 bg-white text-black p-4 containerr rounded-lg">
          <div className="flex flex-row flex-wrap justify-between">
            <h2>Donors</h2>
            <div className="flex flex-row border-2 border-gray-400 rounded-lg px-2 containerr">
              <button
               onClick={handleSortTopDonors}
              className="flex flex-row mr-4">
                <IoPerson className="h-4 w-4 mr-1 mt-1" />
                Top Donors
              </button>
              <div className="border-l-2  h-4 mt-1 mr-3"></div>
              <button className="flex flex-row">
                <LuClock3 
                onClick={handleSortLatestDonors}
                className="h-4 w-4 mr-1 mt-1" />
                Latest
              </button>
            </div>
          </div>
          <div>
            {donors.slice(0,6).map((donor:any) => (
              <div key={donor.name} className="mt-6 border-b-2 border-gray-500 text-xl font-semibold font-['Goudy Old Style'] flex items-center">
                <img
                  src={donor.image}
                  alt="Profile"
                  className="w-[50px] h-[50px] object-cover rounded-full mr-4"
                />
                <div className="w-full">
                  <div>{donor.name}</div>
                  <div className="flex flex-row justify-between">
                    <div>{donor.donationCount}x Donate</div>
                    <div>${donor.totalDonation}</div>
                  </div>
                </div>
              </div>
            ))}
         </div>
         </div>
         <div className=" mb-4 bg-white text-black  containerr p-4 rounded-lg ">
          <div className="flex flex-row justify-between ">
            <h2>Statistics</h2>
            <button className="flex flex-row border-2 border-gray-400 rounded-lg px-2 containerr">
            <CiDollar className="w-6 h-5 mt-0.5 "/>Total
            </button>
          </div>
         <ReactEcharts option={admiReqByReqTypeData} className=" w- ful h-96 mt-10" />
         <div className="flex flex-row justify-between mt-16  border-2   border-gray-400 rounded-lg px-4 containerr">
              <button
              className="flex flex-row mr-4">
                <BsCalendar2MonthFill className="h-4 w-4 mr-1.5 mt-1"/>
               Month
              </button>
              <div className="border-l-2 h-4 mt-1 mr-3"></div>
              <button className="flex flex-row">
              <IoCalendarNumberOutline className="h-4 w-4 mr-1.5 mt-1"/>
              Week
              </button>
              <div className="border-l-2  h-4 mt-1 mr-3"></div>
              <button className="flex flex-row">
                <LuClock3 
                className="h-4 w-4 mr-1 mt-1" />
                Time
              </button>
            </div>
         </div>
         
         </div>
        </div>
  
  </>
  );
}

export default Donation;
