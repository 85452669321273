/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import '../../../App.css'
import { useDarkMode } from '../../contextApis/DarkModeContext'
import logo from '../images/upflogo_with_typeandacronym-rv0602-2011-1080p-01 1.png';
import { IoMdPerson } from "react-icons/io";
import User from "../images/847-8474751_download-empty-profile.png";
import { MdOutlineWbSunny } from "react-icons/md";
import { FaMoon } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from 'react-i18next';

const TitleStyle = {
  fontFamily: 'Goudy Old Style',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
};


interface NavbarProps {
  menuItems: {
    colorClass: any; to: string; icon: string; text: string 
  }[];
  setSelectedMenu: React.Dispatch<React.SetStateAction<any>>;
  isSidebarOpen: boolean;
}
interface User {
  picture: string;
}

const Navbar: React.FC<NavbarProps> = ({ menuItems, setSelectedMenu }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { darkMode, toggleDarkMode } = useDarkMode();
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User : null;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  const toggleSidebar = () => {
    console.log('Toggling sidebar');
    setIsSidebarOpen(!isSidebarOpen);
  };
  
    const { i18n } = useTranslation();
  
  const changeLanguage = (lng:any) => {
    i18n.changeLanguage(lng);
  }
const handleItemClick = (index: any) => {
  setSelectedItem(index);
  setSelectedMenu(menuItems[index].text);
};
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const toggleNotificationDropdown = () => setShowNotificationDropdown(!showNotificationDropdown);
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  const navigate = useNavigate();
  const viewProfile = () => {
   navigate('/profile')
  };

  return (
    <div className="container mx-auto flex flex-col items-center">
      <div className="p-4 flex justify-between w-full">
      <div className="lg:hidden  items-center space-x-4 relative">
  <div className="cursor-pointer items-center mt-2"  onClick={toggleSidebar}>
    <RxHamburgerMenu className={`h-6 w-6 ${darkMode ? 'dark' : ''}`} />
  </div>
  <div className=''>
        <select onChange={(e) => changeLanguage(e.target.value)} defaultValue={i18n.language} className='rounded-md w-20 h-8'>
        <option value="en">English</option>
        <option value="po">Portugese</option>
        <option value="fr">French</option>
      </select>
</div>
{isSidebarOpen && (
        <div className="lg:hidden fixed inset-0 z-50 overflow-hidden">
        <div className="flex h-full">
          <div className="bg-gray-800 text-white w-64 h-full">
            <div className="flex items-center  mt-6" onClick={toggleSidebar}>
            <IoMdClose className='w-10 h-10'/>
            
            </div>
            <div className='flex flex-row mt-4  lg:hidden  lg:w-40'>
        <img src={logo} alt="Logo" />
        <h2 className='mt-2' style={TitleStyle}>UPF AFRICA</h2>
      </div>
            <nav className="mt-10">
            
              <ul className="space-y-4">
              {menuItems.map((menuItem:any, index) => (
                <li
                  key={index}
                  className={`flex items-center mb-6 mr-2 cursor-pointer gap-1   ${selectedItem === index ? 'border-radius-lg shadow-md containerr' : ''}`}
                  onClick={() => handleItemClick(index)}
                >
                  <Link to={menuItem.to} className={`flex items-center  mr-2 cursor-pointer px-1 py-2 gap-1  ${selectedItem === index ? 'border-radius-lg shadow-md' : ''} `} onClick={() => handleItemClick(index)}>
                    <div className='mr-3'>
                      {React.cloneElement(menuItem.icon, { className: `${menuItem.colorClass} ${darkMode ? 'dark' : ''}` })}
                    </div>
                    <h2
                      style={{
                        
                        fontFamily: 'Goudy Old Style',
                        fontSize: '17px',
                        fontWeight: 600,
                      }}
                    >
                      {menuItem.text}
                    </h2>
                  </Link>
                </li>
              ))}
             </ul>
      </nav>
    </div>
  </div>
</div>
      )}
    
      </div>
      <div className='hidden lg:block'>
        <select onChange={(e) => changeLanguage(e.target.value)} defaultValue={i18n.language} className='rounded-md w-56 h-8'>
        <option value="en">English</option>
        <option value="po">Portugese</option>
        <option value="fr">French</option>
      </select>
</div>
        <div className='flex flex-row lg:hidden  lg:w-40'>
        {/* <img src={logo} alt="Logo" />
        <h2 className='mt-2' style={TitleStyle}>UPF AFRICA</h2> */}
       
      </div>
      <div className="flex-grow  lg:block hidden "></div>
        <div className="flex flex-row space-x-4 ml-4 items-center">
          <div className='rounded-lg border  border-gray-400 p-1 flex flex-row mr-6 gap-2'>
       
            <p className='font-bold'> Notification</p>
            {showNotificationDropdown?(
              <>
            <IoMdArrowDropdown className=' font-bold text-2xl cursor-pointer' onClick={()=>toggleNotificationDropdown()}/>
            </>
          ):(
            <>
              <IoMdArrowDropup className=' font-bold text-2xl cursor-pointer' onClick={()=>toggleNotificationDropdown()}/>
              </>
            )}
            {showNotificationDropdown && (
            <div className="absolute right-20 mt-9 w-60 bg-white rounded-md shadow-xl  z-20">
             <table className="w-full bg-red-300 rounded-md">
                        <thead className='text-left'>
               <tr>
                {/* <th className='px-2'>No</th>
                <th className='px-2'>Datails</th> */}
               </tr>
              </thead>
              <tbody className='flex justify-center w-full '>
                  <tr className=' text-red-600 px-9 text-2xl font-bold'>
                 
                    Comming Soon
                  </tr>
              </tbody>
             </table>
            </div>
          )}
          </div>
          {darkMode?(
            <>
            <MdOutlineWbSunny className='w-6 h-6 cursor-pointer' onClick={toggleDarkMode} />
            </>
          ):
          ( <FaMoon  className='w-6 h-6 cursor-pointer' onClick={toggleDarkMode}  />)}
         
        <div className="relative">
        <button
              onClick={toggleDropdown}
              className="focus:outline-none flex flex-col"
            >
        <img
          src={`${user?.picture ? `https://fm.upfafrica.org${user?.picture}` : '/default-profile-image.png'}`}
          alt="Profile"
          className="w-8 h-8 object-cover rounded-full  containerr"
        />
        {showDropdown && (
            <div className="absolute right-0 mt-6 w-48 bg-white rounded-md shadow-xl z-20">
              <a
                href="#"
                onClick={viewProfile}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
              >
                View Profile
              </a>
              <a
                href="#"
                onClick={handleLogout}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
              >
                Logout
              </a>
            </div>
          )}
        </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
