import React, { InputHTMLAttributes, ReactNode } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: string;
  placeholder: string;
}

const Input: React.FC<InputProps> = ({ icon, placeholder, ...props }) => {
  return (
    <div className="relative w-6/12 ml-4 ">
      <input
        className="pl-6 pr-4 py-4  rounded-lg w-full border-2 border-black"
        type="text"
        placeholder={placeholder}
        {...props}
      />
      <div className="absolute inset-y-0 inset-x-0 right-1 pl-2 flex items-center">
        <img
          src={icon}
          alt="Icon"
          className="h-3"
        />
      </div>
    </div>
  );
};

export default Input;
