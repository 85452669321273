import React, { useState } from 'react';
import Select, { SingleValue, StylesConfig } from 'react-select';
import { useDarkMode } from '../contextApis/DarkModeContext';

interface ReligionSelectProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

const religions = [
  { value: 'agnosticism', label: 'Agnosticism' },
  { value: 'atheism', label: 'Atheism' },
  { value: 'bahai', label: 'Baháʼí' },
  { value: 'buddhism', label: 'Buddhism' },
  { value: 'christianity', label: 'Christianity' },
  { value: 'confucianism', label: 'Confucianism' },
  { value: 'hinduism', label: 'Hinduism' },
  { value: 'islam', label: 'Islam' },
  { value: 'jainism', label: 'Jainism' },
  { value: 'judaism', label: 'Judaism' },
  { value: 'shinto', label: 'Shinto' },
  { value: 'sikhism', label: 'Sikhism' },
  { value: 'taoism', label: 'Taoism' },
  { value: 'zoroastrianism', label: 'Zoroastrianism' },
  { value: 'druze', label: 'Druze' },
  { value: 'gnosticism', label: 'Gnosticism' },
  { value: 'mormonism', label: 'Mormonism' },
  { value: 'rastafarianism', label: 'Rastafarianism' },
  { value: 'scientology', label: 'Scientology' },
  { value: 'shamanism', label: 'Shamanism' },
  { value: 'tenrikyo', label: 'Tenrikyo' },
  { value: 'unitarian-universalism', label: 'Unitarian Universalism' },
  { value: 'wicca', label: 'Wicca' },
  { value: 'paganism', label: 'Paganism' },
  { value: 'native-american', label: 'Native American Religions' },
  { value: 'spiritualism', label: 'Spiritualism' },
  { value: 'new-age', label: 'New Age' },
  { value: 'humanism', label: 'Humanism' },
  { value: 'animism', label: 'Animism' },
];

const ReligionSelect: React.FC<ReligionSelectProps> = ({ formData, setFormData }) => {
  const { darkMode } = useDarkMode();
  const [selectedReligion, setSelectedReligion] = useState<SingleValue<{ value: string; label: string }>>(null);

  const handleChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    setSelectedReligion(selectedOption);
    setFormData({
      ...formData,
      religion: selectedOption?.value || '',
    });
  };

  const customStyles: StylesConfig<{ value: string; label: string }, false> = {
    control: (provided:any) => ({
      ...provided,
      backgroundColor: darkMode ? '#333' : '#fff',
      color: darkMode ? '#fff' : '#000',
      borderColor: darkMode ? '#555' : '#ccc',
    }),
    menu: (provided:any) => ({
      ...provided,
      backgroundColor: darkMode ? '#333' : '#fff',
      color: darkMode ? '#fff' : '#000',
    }),
    singleValue: (provided:any) => ({
      ...provided,
      color: darkMode ? '#fff' : '#000',
    }),
    input: (provided:any) => ({
      ...provided,
      color: darkMode ? '#fff' : '#000',
    }),
    placeholder: (provided:any) => ({
      ...provided,
      color: darkMode ? '#aaa' : '#666',
    }),
  };

  return (
    <Select
      options={religions}
      value={selectedReligion}
      onChange={handleChange}
      styles={customStyles}
      className="w-full mt-1"
    />
  );
};

export default ReligionSelect;
