import React,{useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { FaChevronLeft } from "react-icons/fa";
import { useDebounce } from 'use-debounce';
import { useNavigate } from "react-router-dom";
import { Select ,Pagination, Empty, notification} from 'antd';
import { FadeLoader } from "react-spinners";
import Chart from "react-google-charts";
interface User {
    userType: number;
    subRegionId:number;
    regionId:number;
    nationality:number;
  }
const Nation=()=>{
    const{id}=useParams()
    const [usersData, setUsersData] = useState<any[]>([]);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const [nationList, setNationList] = useState<any[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage=50
   const national=id

    const navigate = useNavigate();
    const { darkMode } = useDarkMode();
    const formatChartData = () => {
       // const nationalityCounts = calculateNationalityCounts();
        const chartData = [['', 'Number of People', { role: 'style' }]];
        const fetchedNationalities = usersData.map(user => user.nationality).filter(n => n); // Filter out null or undefined values
        const uniqueNationalities = Array.from(new Set(fetchedNationalities)); // Get unique nationalities
        
        uniqueNationalities.forEach((id) => {
          const nation = nationList.find(n => n.id === id);
          const count = fetchedNationalities.filter(n => n === id).length;
          let color = '';
          switch (id) {
            case 1:
              color = '#b87333'; // Brown
              break;
            case 2:
              color = '#3366cc'; // Blue
              break;
            case 3:
              color = '#dc3912'; // Red
              break;
            case 4:
              color = '#ff9900'; // Orange
              break;
            case 5:
              color = '#109618'; // Green
              break;
            default:
              color = '#3366cc'; // Black (default color)
              break;
          }
          chartData.push([(nation?.name) || 'Unknown', count, color]);
        });
      
        return chartData;
      };
      const countryData = formatChartData();
    const GetAllUsers = async (): Promise<any> => {
        try {
          const apiUrl = 'https://api.upfafrica.org/admin/user/list';
          const token = localStorage.getItem('token');
    
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: {},
              options: {
                select: [
                  'id',
                  'name',
                  'position',
                  'upfPosition',
                  'regionId',
                  'subRegionId',
                  'nationality',
                ],
                "paginate": 50000
              },
              isCountOnly: false,
            }),
          });
    
          if (!response.ok) {
            console.error('Request failed:', response.status, response.statusText);
            throw new Error('Request failed. ');
          }
    
          const userData = await response.json();
    
          console.log(userData.data); 
    
          if (Array.isArray(userData.data.data)) {
            const filteredData = userData.data.data.filter((item: any) => {
                console.log('Filtering item:', item);
                console.log('item.nationality:', String(item.nationality));
                console.log('national:', String(national));
                return String(item.nationality) === String(national);
            });
            setUsersData(filteredData);
            console.log('Filtered user data:', filteredData)
           
          } else {
            console.error('Invalid user data format:', userData);
            throw new Error('Invalid user data format. ');
          }
    
          return userData;
        } catch (error) {
          console.error('Error:', error);
          return error;
        }
      };
      console.log(usersData); 
      const deleteUser = async (id: any) => {
        try {
            const apiUrl = `https://api.upfafrica.org/admin/user/delete/${id}`;
            const token = localStorage.getItem('token');
    
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
    
            if (!response.ok) {
                console.error('Request failed:', response.status, response.statusText);
                throw new Error('Request failed.');
            }
    
            const responseData = await response.json();
            console.log('User deleted successfully:', responseData);
    
            // Display a success notification
            notification.open({
                message: 'User Deleted',
                description: 'The user has been deleted successfully.',
                duration: 3, // Duration in seconds
            });
         
            GetAllUsers();
    
            return responseData;
        } catch (error) {
            console.error('Error:', error);
    
            // Display an error notification
            notification.error({
                message: 'Error Deleting User',
                description: 'An error occurred while deleting the user.',
                duration: 3,
            });
    
            return error;
        }
    };
      const showModal = (id: any) => {
        setUserIdToDelete(id);
        setIsModalVisible(true);
    };
      const fetchData = async () => {
        const token = localStorage.getItem('token');
      const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "query": {},
                    "options": {
                        "select": [
                            "id",
                            "name",
                        ],
                        "paginate": 366
                    },
                    "isCountOnly": false
                }),
            });
            const nationData = await nationResponse.json();
            if (nationData?.data) {
                setNationList(nationData.data.data);
            } else {
                console.error('Invalid nation data:', nationData);
                throw new Error('Invalid nation data.');
            }
          }
          const getNationNameById = (id: any) => {
            if (!id || !Array.isArray(nationList) || nationList.length === 0) {
                return '';
            }
            const nation = nationList.find(n => n.id === id);
            return nation ? nation.name : '';
        };
      useEffect(()=>{
        fetchData()
        GetAllUsers()
      },[])
        
      const isEmpty = Array.isArray(usersData) && usersData.length === 0;
const isEmptyNation = Array.isArray(nationList) && nationList.length === 0;
const toTitleCase = (str:any) => {
    return str.replace(/\w\S*/g, (txt:any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
const handleEdit = (id: any) => {
    navigate(`/EditAmbassador/${id}`);
  };
const handlePageChange=(page:number)=>{
    setCurrentPage(page)
  }
  const back=()=>{
    navigate(-1)
   }
const paginatedData=usersData.slice((currentPage-1)*itemsPerPage,currentPage*itemsPerPage) 
if (usersData.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
}

    return(
        <div>
             <button onClick={back} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
           <p className='text-xl font-semibold ml-3'>Back</p>
          </button>
             <div className='w-[95%]  containerrr rounded-lg p-3 ml-4  overflow-hidden shadow-lg bg-white '>
              <p className='text-2xl font-semibold'>Population</p>
            <Chart
            className={`rounded-lg${ darkMode ? '#fff' : '#000' }`}
  width={'95%'}
  height={'400px'}
  chartType="ColumnChart"
  loader={<div>Loading Chart</div>}
  data={countryData}
  options={{
    textStyle: { color: darkMode ? '#fff' : '#000' },
    chartArea: { width: '60%' },
    hAxis: {
      title: '',
      minValue: 0,
      textStyle: { color: darkMode ? '#fff' : '#000' }
    },
    vAxis: {
      title: '',
      textStyle: { color: darkMode ? '#fff' : '#000' }
    },
    backgroundColor: darkMode ? '#333' : 'white', // Set background color
    legend: 'none', // Hide legend
  }}
/>
</div>
            <div className="w-[95%]  bg-white rounded-lg mt-9 p-5 ml-5 containerrr">
  <table className="w-full ">
                        <thead className='text-left'>
                            <tr>
                                <th className="py-2">NO</th>
                                <th className="py-2 px-3">Name</th>
                                <th className="py-2">Position</th>
                                <th className="py-2">Nationality</th>
                                {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) &&<th className='py-2'>Action</th>}
                            </tr>
                        </thead>
                        {paginatedData?.length > 0 ? (
                            <tbody>
                                {paginatedData.map((user, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-1">{(currentPage-1)*itemsPerPage+index + 1}</td>
                                        <td className="py-2 px-3">{toTitleCase(user.name)}</td>
                                        <td className="py-2 w-2/5">{((user.position || user.upfPosition) && (user.position || user.upfPosition).split(' ').slice(0, 120).join(' '))}
</td>
                                        <td className="py-2 w-1/4">{user?.nationality && getNationNameById(user?.nationality)}</td>
                                        {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && (
                                            <td className="py-2 flex flex-row">
                                                <FontAwesomeIcon
                                                    onClick={() => showModal(user?.id)}
                                                    className="cursor-pointer"
                                                    color="#FF0000"
                                                    icon={faTrash}
                                                />
                                                <CiEdit className="cursor-pointer ml-2 w-11" onClick={() => handleEdit(user?.id)} />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        ) : <Empty description="No Data Found" />}

                    </table>
                    <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={usersData.length}
            onChange={handlePageChange}
            style={{ textAlign: 'center', marginTop: '20px' }}
            itemRender={(page, type, originalElement) => {
                if (type === 'page') {
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            style={{
                                //background:darkMode?'black':'inherit',
                                color: darkMode ? 'white ' : 'inherit'
                            }}
                        >
                            {page}
                        </a>
                    );
                }
                return originalElement;
            }}
        />
        </div>
        </div>
    )
}
export default Nation