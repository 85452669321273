import React, {  useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { Button, Input, Modal, Pagination, Select, notification } from 'antd';
import { t } from "i18next";
import User from "../images/conference.jpg";
import { FadeLoader } from "react-spinners";
import { FcDeleteDatabase } from "react-icons/fc";
import { useSummits } from '../../contextApis/SummitProvider';
import { useDebounce } from "use-debounce";
const { Option } = Select;
interface EventFormData {
    name: boolean;
   
  }
  interface Usr {
    id: number;
    name: string;
  }
  
  interface Summit {
    id: number;
    participantsId: number[];
  }
  
  interface Participant {
    summitId: number;
    participantNames: string[];
  }
  
interface User {
  id:string
  userType: number;
  subRegionId:number;
  nationId:number
}

const SummitApproval: React.FC = () => {
  const navigate = useNavigate();
  const [summitsData, setSummitsData] = useState<any[]>([]);
 
  const [filteredSummitsData, setFilteredSummitsData] = useState<any[]>([]);
  const [nationList, setNationList] = useState<any[]>([]);
  const { setSummitsCount } = useSummits();
 
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activityIdToDelete, setActivityIdToDelete] = useState(null);
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User : null;
  const [loading, setLoading] = useState(true);
 const darkMode=useDarkMode()
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 20000); // 20000 milliseconds = 20 seconds

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  
  const getAllActivities = async () => {
    try {
      const apiUrl = 'https://api.upfafrica.org/admin/activity/list';
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "query": {
             "isDeleted": false,
            //  "status":"Pending",
          },
          "options": {
              "select": [
                  "id",
                  "name",
                  "coverImage",
                  "host",
                  "participantsId",
                  "date",
                  "theme",
                  "status",
                  "nationId",
                  "addedBy",
                  "subRegionId",
                  "isDeleted"
              ],
              "paginate": 3000
          },
          "isCountOnly": false
        }),
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }

      const userData = await response.json();
     
    if (Array.isArray(userData.data.data)) {
        setSummitsData(userData?.data?.data?.filter((item: any) => {
           // const userTypeCheck = item?.userType === 8 || item?.userType === 5;
            const subRegionCheck = item?.subRegionId === user?.subRegionId;
            const nationalCheck = item?.addedBy === user?.id;
            console.log(nationalCheck)
            if (user?.userType === 8 && item?.status==='Pending' ) {
                return true
            }
            else if (user?.userType === 2 && item?.status==='Pending')  {
                return  subRegionCheck 
            } 
            else if (user?.userType === 3 && item?.status==='Edit')  {
              return nationalCheck
            } else if (user?.userType === 3 && item?.status==='Pending')  {
                return nationalCheck
            
            }else if (item?.addedBy === user?.id && item?.status==='Approved')  {
                return  true
            }else {
                return false;
            }
          }))
        }
       ;
      
      const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "query": {
            "isDeleted": false
          },
          "options": {
            "select": [
              "id",
              "name",
            ],
            "paginate": 666
          },
          "isCountOnly": false
        }),
      });

      if (!nationResponse.ok) {
        console.error('Nation request failed:', nationResponse.status, nationResponse.statusText);
        throw new Error('Nation request failed.');
      }

      const nationData = await nationResponse.json();
      setNationList(nationData.data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getAllActivities();
    
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    function stripTime(date: any) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const currentDateOnly = stripTime(currentDate);

    const filteredData = summitsData.filter(summit => {
      const dbDate = new Date(summit.date);
      const dbDateOnly = stripTime(dbDate);
      // const subRegionCheck = (summit?.nationId === user?.nationId || summit?.subRegionId === user?.subRegionId || user?.userType === 8);
  
      if (statusFilter === 'Current Events') {
          return (dbDateOnly.getTime() === currentDateOnly.getTime());
      } else if (statusFilter === 'Past Events') {
          return (dbDateOnly.getTime() < currentDateOnly.getTime());
      } else if (statusFilter === 'Future Events') {
          return (dbDateOnly.getTime() > currentDateOnly.getTime());
      } else {
          return true;
      }
  });

    setFilteredSummitsData(filteredData);
    const pendingSummitsCount = filteredData.filter(item => item.status === 'Pending').length;
     setSummitsCount(pendingSummitsCount);
  }, [statusFilter, summitsData]);
 
  
  const deleteActivity = async (id: any) => {
    try {
      const apiUrl = `https://api.upfafrica.org/admin/activity/softDelete/${id}`;
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }

      const responseData = await response.json();
      console.log('Activity deleted successfully:', responseData);

      getAllActivities();

      notification.open({
        message: 'Activity Deleted',
        description: 'The activity has been deleted successfully.',
        duration: 3, // Duration in seconds
      });

      return responseData;
    } catch (error) {
      console.error('Error:', error);

      notification.error({
        message: 'Error Deleting activity',
        description: 'An error occurred while deleting the activity.',
        duration: 3,
      });

      return error;
    }
  };

  const showModal = (id: any) => {
    setActivityIdToDelete(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteActivity(activityIdToDelete);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };
 
  if (loading && filteredSummitsData.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
  }
  if (summitsData.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} className="flex flex-col">
          <button
          onClick={() => navigate('/add-event')}
          className="containerr w-2/12 h-10 mr-3 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue"
        >
          + Add
        </button>
          <FcDeleteDatabase color="#367fd6" className='w-48 h-48' />
          <p className="text-2xl font-semibold">No data Found/Check your Internet Connection</p>
        </div>
    );
  }
  const getNationNameById = (id: any) => {
    const nation = nationList.find(n => n.id === id);
    return nation ? nation.name : '';
  };
 
// const summitCount=()=>{
//   return <SummitApprovalNumber Count={summitsData.length}/>
// }
const activitiesColors: { [key: string]: string } = {
  Approved: 'text-green-900',
  Edit: 'text-red-900',
  Pending:'text-yellow-900',
};
const activitiesBG: { [key: string]: string } = {
  Approved: 'bg-green-400',
  Edit: 'bg-red-400',
  Pending:'bg-yellow-400',
};
  return (
    <div className="w-full">
      <div className="flex justify-between mt-6">
      <Select
    defaultValue=""
    className="w-48"
    style={{  marginRight: 16 }}
    onChange={(value) => setStatusFilter(value)}
>
<Option value="">{t('All Events')}</Option>
<Option value="Current Events">{t('Current events')}</Option>
<Option value="Future Events">{t('Future events')}</Option>
<Option value="Past Events">{t('Past Events')}</Option>
</Select>
        {user && (user.userType === 8 || user.userType === 6 || user.userType === 7) && (<button
          onClick={() => navigate('/add-event')}
          className="containerr w-2/12 h-10 mr-3 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue"
        >
          + Add
        </button>)}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-12">
        {filteredSummitsData?.map((summit: any, id: number) => (
          <div className="flex flex-col containerr justify-between bg-white border-2  w-80 rounded-md shadow-lg ">
            <div>
              
            <div className="">
        <img
          src={summit?.coverImage || User}
          alt="Profile"
          className={`h-48 object-center w-80  mb-4 ${darkMode?'bg-slate-300':''}`}
        />
        </div>
        
            <div className="flex flex-row justify-between p-2" >
              <div className="flex flex-col">
              <div>
            <Link key={id} to={`/approval/${summit?.id}`}>
              <p className=" font-semibold ">{summit?.name || 'No Name'}</p>
              </Link>
              </div>
              {/* <div className="flex flex-row  gap-2 mt-4">
                      <FontAwesomeIcon onClick={() => showModal(summit?.id)} className="cursor-pointer  w-5 h-5" color="#FF0000" icon={faTrash} />
                      <CiEdit size={23} className="text-blue-800 cursor-pointer" onClick={()=>navigate(`/SummitEdit/${summit.id}`)}/>
            </div> */}
            </div>
              <div className="flex flex-col ">
              <div className="containerrr  rounded-lg  px-4   text-center text-gray-800 font-semibold">
                {summit.nationId ? getNationNameById(summit.nationId) : 'No Nation'}
              </div>
              
            </div>  
            </div>
            <div className="p-2 flex flex-row justify-between">
                <div>
        <p className="font-semibold">Theme:</p>
        <p> {summit.theme}</p>
      </div>
      <Link key={id} to={`/approval/${summit?.id}`}>
<div className={`p-3 border shadow-lg ${activitiesBG[summit?.status] || ''}  rounded-lg w-32 flex justify-center`}>
    <p className={` font-semibold ${activitiesColors[summit?.status] || ''}`}>{summit?.status}</p>
</div>
</Link>
          </div>
          </div>
            </div>
        ))}
       {}
      </div>
      <Modal
              title="Delete User"
              visible={isModalVisible}
              onOk={handleDelete}
              onCancel={() => setIsModalVisible(false)}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
            >
              <p>Are you sure you want to delete this activity?</p>
            </Modal>
    </div>
  );
};
const SummitsDetailApproval: React.FC = () => {
  const { id } = useParams();
  const [summit, setSummit] = useState<any>(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterValue, setFilterValue] = useState('');
  const [debouncedFilterValue] = useDebounce(filterValue, 200);
  const [formData, setFormData] = useState<EventFormData | null>(null);
  const [comment, setComment] = useState<string>('');
  const[participants,setParticipants]=useState<any[]>([])
  const [nationList, setNationList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [commentsArray, setCommentsArray] = useState<string[]>([]);
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User : null;
  const subRegionCheck = (user?.userType === 2 || user?.userType === 8);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const handleSave = () => {
    setIsModalVisible(false);
    handleApprove(summit.id,'Edit',comment)
    navigate('/approval')
  };
  useEffect(() => {
    const getSummitDetails = async () => {
      console.log(id)
      try {
        const apiUrl = `https://api.upfafrica.org/admin/activity/${id}`;
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          console.error('Request failed:', response.status, response.statusText);
          throw new Error('Request failed.');
        }

        const summitData = await response.json();
        console.log('summitData:', summitData);
        setSummit(summitData.data);
        console.log(summit)
        
        const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              "query": {},
              "options": {
                  "select": [
                      "id",
                      "name",
                  ],
                  "paginate": 166
              },
              "isCountOnly": false
          }),
      });
      const nationData = await nationResponse.json();
      if (nationData?.data) {
          setNationList(nationData.data.data);
      } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
      }
 

        const UserResponse = await fetch('https://api.upfafrica.org/admin/user/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            "query": {
              "isDeleted": false
            },
            "options": {
              "select": [
                "id",
                "name",
                "position",
                "upfPosition",
                "nationality"
              ],
              "paginate": 50000
            },
            "isCountOnly": false
          }),
        });
  
        if (!UserResponse.ok) {
          console.error('user request failed:', UserResponse.status, UserResponse.statusText);
          throw new Error('user request failed.');
        }
  
        const UserData = await UserResponse.json();
        if (UserData?.data?.data) {
          const filteredData = UserData.data.data.filter((item: any) => {
            return (
              (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
            );
          });
  
          setUserList(filteredData);
          if (filteredData.length > 0 && summit && Array.isArray(summit.participantsId)) {
            const participant = summit.participantsId.map((id: any) => {
              const user = filteredData.find((user:any) => user.id === id);
              return user ? user : null;
            }).filter((name: any) => name !== null) as string[]; // Remove null values and cast to string[]
  
            setParticipants(participant);
          }
        } else {
          setUserList([]);
        setParticipants([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    if (id) {
      getSummitDetails();
    }
  }, [id, debouncedFilterValue,summit, userList]);
  // useEffect(() => {
    
  // }, [summit, userList]);
  console.log(participants)
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
  if (!summit) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FadeLoader color="#367fd6" className='w-96 h-96' />
    </div>
    );
  }
  const handleApprove = async (id:any,action:string,editRequest:any) => {
    // action.preventDefault();
  
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }
  
    try {
      const response = await fetch(`https://api.upfafrica.org/admin/activity/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({...formData!,status:action,editRequest:commentsArray }),
      });
  
      if (!response.ok) {
        const Error = await response.json();
        console.error('Failed to submit form', Error);
        return;
      }
      const responseData = await response.json();
      navigate(`/approval`);
      console.log(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const AddComment = () => {
    if (comment && comment.trim()) { 
        commentsArray.push(comment);
    } else {
        console.error("Comment cannot be empty.");
    }
    setComment('')
};
const Cancel=()=>{
  setIsModalVisible(false)
  setCommentsArray([])
}
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setFilterValue(e.target.value);
  setCurrentPage(1); // Reset to the first page when the filter changes
};
const getNationNameById = (id: number) => {
  const nation = nationList.find(nation => nation.id === id);
  return nation ? nation.name : 'Unknown';
};
const paginatedparticipantsData = participants.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
  return (
    <div className="p-10 relative">
      <h1 className="mb-4 font-bold text-3xl" > {summit.name}</h1>
      <div className="relative ">
        <img
          src={summit.coverImage || User}
          alt="Summit"
          style={{ height: "50vh" }}
          className="w-full object-center rounded-lg mb-4 bg-gray-600"
        />
      </div>
      <div className="flex flex-row gap-3">
      {( summit?.status ==='Pending'&& subRegionCheck)&&(<button
        className="cursor-pointer border  bg-green-300 p-3 w-32 rounded-lg shadow-lg"
        onClick={()=>handleApprove(summit.id,'Approved','')}
        >
        
        <p className="text-green-700 font-semibold">Approve</p>
        </button>)} 
        {( summit?.status ==='Pending'&& subRegionCheck)&&( <button
        className="cursor-pointer border  bg-red-500 p-3 w-32 rounded-lg shadow-lg"
        onClick={()=>handleApprove(summit.id,'Declined','')}
        >
         <p className="text-red-900 font-semibold">Decline</p>
        </button>)} 
        {( summit?.status ==='Pending' && subRegionCheck)&&(<button
        className="cursor-pointer border  bg-yellow-500 p-3 w-32 rounded-lg shadow-lg"
         onClick={()=>setIsModalVisible(true)}
        >
         <p className="text-yellow-900 font-semibold">Request-Edit</p>
        </button>)} 
        {summit?.status === 'Edit' && (
  (() => {
    let features = [];
    try {
      features = summit.editRequest;
    } catch (e) {
      console.error('Failed to parse editRequest', e);
    }
    
    return Array.isArray(features) ? (
      <ul className="bg-gray-300 rounded lg containerr p-3">
        <p className="font-semibold ">Comments:</p>
        {features.map((feature: string, index: any) => (
          <li key={index} className="flex items-center py-2  w-ful rounded lg">
            <svg className="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M2.293 7.293a1 1 0 011.414 0L10 13.586l6.293-6.293a1 1 0 111.414 1.414l-7 7a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            <span className="ml-2">{feature}</span>
          </li>
        ))}
      </ul>
    ) : null;
  })()
)}
{/* <CiEdit size={23} className="text-blue-800 cursor-pointer" onClick={()=>navigate(`/SummitEdit/${summit.id}`)}/> */}
 {( summit?.status ==='Edit'||summit?.status ==='Approved'||summit?.addedBy===user?.id)&&(<button
        className="cursor-pointer border  bg-blue-300 p-3 w-32 h-14 rounded-lg shadow-lg"
        onClick={()=>navigate(`/SummitEdit/${summit.id}`)}
        >
        
        <p className="text-blue-700 font-semibold">Edit</p>
        </button>)} 
        {( summit?.status ==='Edit')&&(<button
        className="cursor-pointer border  bg-green-300 p-3 w-32 h-14 rounded-lg shadow-lg"
        onClick={()=>handleApprove(summit.id,'Pending','')}
        >
        
        <p className="text-green-700 font-semibold">Re-submit</p>
        </button>)} 
        
          </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 mt-10">
        <p className="" style={getTitleStyle()}>Hosted by: {summit.host}</p>
        <p className="" style={getTitleStyle()}>City: {summit.city}</p>
        <p className="mt-4 mb-4"style={getTitleStyle()}>Date: {new Date(summit.date).toLocaleDateString()}</p>
        
      </div>
      <div className=" flex flex-col justify-between ">
      <div>
        <p className="mt-4 mb-4 "style={getTitleStyle()}>Description:</p>
        {summit.description}
       
      </div>
      <p className="mt-4 mb-4 "style={getTitleStyle()}>Participants:</p>
      <div className="bg-white rounded lg containerr p-8">
      <Input
                placeholder="Search by name, position, or nationality"
                value={filterValue}
                onChange={handleInputChange}
                className="mb-4 w-64"
            />
      <table className="w-full">
  <thead className='text-left'>
    <tr>
      <th className="py-2">NO</th>
      <th className="py-2 px-3">Name</th>
      <th className="py-2">Position</th>
      <th className="py-2">Nationality</th>
    </tr>
  </thead>
  <tbody>
    {paginatedparticipantsData.length > 0 ? paginatedparticipantsData.map((summit, index) => (
      <tr key={summit.id}>
        <td className="py-2">{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td className="py-2 px-3">{summit.name}</td>
        <td className="py-2">{summit.position||summit.upfPosition}</td>
        <td className="py-2">{summit.nationality && getNationNameById(summit.nationality)}</td>
      </tr>
    )) : (
      <tr>
        <td  className="py-2 text-center">No participants found</td>
      </tr>
    )}
  </tbody>
</table>
<Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={participants.length}
                onChange={handlePageChange}
                className="mt-4"
            />
      {/* <p className="mt-4 mb-4 " style={getTitleStyle()}>Participants List</p>
      {participants.length > 0 && participants.map(summit => (
        <div key={summit.id}>
          <ol className="mb-2 list-decimal">
           
              <li key={summit.id}>{summit.name}</li>
         
          </ol>
        </div>
      ))} */}
    </div>
    </div>
      <Modal
      title="Edit Request"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => Cancel()}>
          Cancel
        </Button>,
         <Button  
         className="bg-blue-400 text-blue-900"
         onClick={()=>AddComment()} 
         
       >
        Add Comment
       </Button>,
        <Button 
          key="save" 
          type="primary" 
          onClick={()=>handleSave()} 
          style={{ backgroundColor: '#FF0000', color: 'white' }}
        >
          Send
        </Button>,
      ]}
    >
      {/* <p className=" font-semibold text-xl">Comment</p> */}
      <TextArea 
        placeholder="Comment" 
        value={comment} 
        onChange={(e) => setComment(e.target.value)} 
        rows={4} // Adjust the number of rows to increase the height
      />
    </Modal>
    
    </div>
  );
};

export { SummitApproval, SummitsDetailApproval };
