import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Image from "../images/847-8474751_download-empty-profile.png";
import ISCP from "../images/ISCP.png";
import IAPD from "../images/IAPD.png";
import IAPP from "../images/IAPP.png";
import IAACP from "../images/IAACP.png";
import IMAP from "../images/IMAP.png";
import IAAP from "../images/IAAP.png";
import IAED from "../images/IAED.png";
import { Pagination} from 'antd';
import { FadeLoader } from 'react-spinners';

interface Association {
  tag: string;
  primaryTag: any;
  count: number;
  
}

interface Use {
  userType: number;
  subRegionId: number;
  nationality: number;
}
interface Nation {
  tag: string;
  id: number;
  name: string;
  decription:string;
}

const Associations: React.FC = () => {
  const [associations, setAssociations] = useState<Association[]>([]);
  const [associationList, setAssociationList] = useState<Nation[]>([]);
  const storedUser = localStorage.getItem('user');
  const User = storedUser ? JSON.parse(storedUser) as Use : null;

  useEffect(() => {
    const fetchAssociations = async () => {
      try {
        const apiUrl = 'https://api.upfafrica.org/admin/user/list';
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['primaryTag', 'subRegionId', 'nationality', 'userType',],
              paginate: 9000,
            },
            isCountOnly: false,
          }),
        });

        if (!response.ok) {
          console.error('Request failed:', response.status, response.statusText);
          throw new Error('Request failed. ');
        }

        const userData = await response.json();

        if (Array.isArray(userData.data.data)) {
          const tempAssociations: { [key: string]: number } = {};
          userData.data.data.forEach((user: any) => {
            const { primaryTag, subRegionId, nationality, userType } = user;
            const subRegionCheck = userType === 8 || subRegionId === User?.subRegionId;
            const nationalityCheck = nationality === User?.nationality;
            if (
              (primaryTag !== null)&&(
              (User?.userType === 8 ) ||
              (((User?.userType === 5) || (User?.userType === 6 )||( User?.userType === 3)) && (nationalityCheck)) ||
              (((User?.userType === 2) || (User?.userType === 7)) && (subRegionCheck)))
            ) {
              tempAssociations[primaryTag] = (tempAssociations[primaryTag] || 0) + 1;
            }
          });
          setAssociations(
            Object.keys(tempAssociations).map((key) => ({
              primaryTag: key,
              tag:key,
              count: tempAssociations[key],
            }))
          );
        } else {
          console.error('Invalid user data format:', userData);
          throw new Error('Invalid user data format. ');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAssociations();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const nationResponse = await fetch('https://api.upfafrica.org/admin/association/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['id', 'name','tag','decription'],
              paginate: 166,
            },
            isCountOnly: false,
          }),
        });
        const nationData = await nationResponse.json();
        if (nationData?.data) {
          setAssociationList(nationData.data.data);
        } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
        }
      } catch (error) {
        console.error('Error fetching nation data:', error);
      }
    };
    fetchData();
  }, []);
  const getAssociationNameById = (id: any) => {
    if (!id || !Array.isArray(associationList) || associationList.length === 0) {
        return '';
    }
    const associate = associationList.find(n => n.id === parseInt(id));
    return associate ? associate.name : '';
};
const getAssociationTagById = (id: any) => {
  if (!id || !Array.isArray(associationList) || associationList.length === 0) {
      return '';
  }
  const associate = associationList.find(n => n.id === parseInt(id));
  return associate ? associate.tag : '';
};
const getDescriptionById = (id: any) => {
  if (!id || !Array.isArray(associationList) || associationList.length === 0) {
      return '';
  }
  const associate = associationList.find(n => n.id === parseInt(id));
  return associate ? associate.decription : '';
};
if (associations.length===0) {
  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <FadeLoader color="#367fd6" className='w-96 h-96' />
      </div>
  );
}
const associationColors: { [key: string]: string } = {
  IAPD: 'text-purple-500',
  ISCP: 'text-yellow-600',
  IAPP:'text-green-700',
  IAACP:'text-green-300',
  IAAP:'text-amber-900',
  IAED:'',
  IMAP:'text-red-600'
};
const associationPics: { [key: string]: string } = {
  IAPD: IAPD,
  ISCP: ISCP,
  IAPP:IAPP,
  IAAP:IAAP,
  IAACP:IAACP,
  IMAP:IMAP,
  IAED:IAED
};
  return (
    <div>
      <div className="grid grid-rows-1 md:grid-rows-1 lg:grid-rows-1 gap-4 p-12">
      {associations.filter(association => association.primaryTag !== null && association.primaryTag !== 10)
.map((association, index) => (
    <Link key={index} to={`/association/${association.primaryTag}`}>
        <div className="flex flex-col justify-between bg-gray-200 border-2 rounded-lg  p-1  containerr ">
        <div className='flex flex-row justify-between '>
        <div className='p-2'>
           
        <p
        key={association.primaryTag || index}
        className={`text-blue-600 font-bold text-2xl ${associationColors[getAssociationTagById(association.primaryTag)] || ''}`}
      >
  {association?.primaryTag && getAssociationNameById(association.primaryTag)}
</p>
           <p className='font-bold text-xl'>({association?.primaryTag && getAssociationTagById(association.primaryTag)})</p>
         </div>
        <img
                src={associationPics[getAssociationTagById(association.primaryTag)] || ''}
                alt="Profile"
                className="h-25 w-25 object-cover rounded-lg p-2 "
           />
           
            </div>
            <div>
              <p>{association?.primaryTag && getDescriptionById(association.primaryTag)}</p>
              <div className='flex flex-row'>
              <p className='font-semibold text-xl mt-6 '>Participants:</p>
            <p className=" py-2 border-2 border-green-500 rounded-lg bg-gray-300 px-4  h-10  w-20 text-center containerrr mt-6 mb-4 ml-2">
             
               {association.count}
            </p>
            </div>
            </div>
        </div>
    </Link>
))}

      </div>
      <div>
        
      </div>
    </div>
  );
};


interface Use {
  userType: number;
  subRegionId: number;
  nationality: number;
}

const AssociationDetails: React.FC = () => {
  const { primaryTag } = useParams();
  const [individuals, setIndividuals] = useState<any[]>([]);
  const[currentPage,setCurrentPage]=useState<number>(1)
  const itemsPerPage=51
  const storedUser = localStorage.getItem('user');
  const User = storedUser ? JSON.parse(storedUser) as Use : null;
  const Association=primaryTag
console.log(primaryTag)
  useEffect(() => {
    const fetchIndividuals = async () => {
      try {
        const apiUrl = 'https://api.upfafrica.org/admin/user/list';
        const token = localStorage.getItem('token');
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: [
                'id',
                'name',
                'regionId',
                'subRegionId',
                'picture',
                'ambassadorStatus',
                'email',
                'username',
                'primaryTag',
                'nationality',
              ],
              paginate: 9000,
            },
            isCountOnly: false,
          }),
        });

        if (!response.ok) {
          console.error('Request failed:', response.status, response.statusText);
          throw new Error('Request failed.');
        }

        const userData = await response.json();
        console.log(userData)
        if (Array.isArray(userData.data.data)) {
          setIndividuals(
            userData.data.data.filter((item: any) => {
              const subRegionCheck = item.subRegionId === User?.subRegionId;
              const nationalityCheck = item.nationality === User?.nationality;
              const OrganizationCheck = String(item?.primaryTag) === String(primaryTag);
              if (User?.userType === 8) {
                return OrganizationCheck && true;
              } else if (User?.userType === 5 || User?.userType === 6 || User?.userType === 3) {
                return nationalityCheck && OrganizationCheck;
              } else if (User?.userType === 2 || User?.userType === 7) {
                return subRegionCheck && OrganizationCheck;
              } else {
                return false;
              }
            })
          );
        } else {
          console.error('Invalid user data format:', userData);
          throw new Error('Invalid user data format.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchIndividuals();
  }, [primaryTag, User?.subRegionId, User?.nationality, User?.userType]);

  if (individuals.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
}
const handlePageChange=(page:number)=>{
  setCurrentPage(page)
}
const paginatedData=individuals.slice((currentPage-1)*itemsPerPage,itemsPerPage*currentPage)
  return (
    <div>
    <div className='grid grid-cols- md:grid-cols-2 lg:grid-cols-3 gap-4 p-12'>
      {paginatedData.map((individual, index) => (
        <div key={index}>
          <div className="bg-gray-200 rounded-lg p-4 w-80 h-100 containerr">
            <div className='flex flex-row'>
              <img
                src={individual.picture || Image}
                alt="Profile"
                className="h-56 w-64 object-cover rounded-lg mb-4 ml-4"
              />
            </div>
            <h2 className="text-xl font-semibold mb-2 text-center">{individual.name}</h2>
            <Link to={`/individual/${individual.id}`} className="containerrrr p-1 px-12 py-3 bg-green-400 ml-12 mt-7 rounded-lg">
              View More
            </Link>
          </div>
        </div>
      ))}
       
    </div>
    <Pagination
                       current={currentPage}
                       pageSize={itemsPerPage}
                       total={individuals.length}
                       onChange={handlePageChange}
                       style={{ textAlign: 'center', marginTop: '20px',marginBottom: '20px'  }}
                    />
    </div>
  );
};


export { Associations, AssociationDetails };
