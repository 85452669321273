import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals.js';
import { DarkModeProvider } from './components/contextApis/DarkModeContext';
import { SummitsProvider } from './components/contextApis/SummitProvider'
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // default language
  resources: {
    en: {
      translation: require('./en.json'),
    },
    po: {
      translation: require('./po.json'),
    },
    fr: {
      translation: require('./fr.json'),
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <SummitsProvider>
    <DarkModeProvider>
      <App />
    </DarkModeProvider>
    </SummitsProvider>
    </I18nextProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
