import React, { useState } from 'react';
import { BiLike } from "react-icons/bi";
const LikeButton = () => {
  const [likes, setLikes] = useState(6);
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = async () => {
      if (!isLiked) {
        setLikes(likes + 1);
        setIsLiked(true);
      }
      else{
        setLikes(likes-1)
        setIsLiked(false)
      }
    }


  return (
    <button onClick={handleLike} className='mt-2'>
     <p>{isLiked ? <BiLike className='w-5 h-5 text-blue-800'/> : <BiLike className='w-5 h-5'/>} </p> 
      {likes}
    </button>
  );
};

export default LikeButton;
