import React, { useEffect, useState  } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { Modal, notification,Input, Pagination } from "antd";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CiEdit } from "react-icons/ci";
import User from "../images/847-8474751_download-empty-profile.png";
import { useDarkMode } from "../../contextApis/DarkModeContext";
import { use } from "i18next";
import { stringify } from "querystring";
import axios from "axios";


interface Individual {
  id(id: any): unknown;
  name: string;
  picture: string;
  updatedBy:any;
  fullname: string;
  regionId: string;
  subRegionId: string;
  nationality: string;
  family_name: string;
  given_name: string;
  gender: string;
  email: string;
  upfPosition: string;
  position: string;
  biography: string;
  religion: string;
  primaryTag: string;
  honorifics: string;
}

const TitleStyle = {
  fontFamily: 'Goudy Old Style',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
};
const NameStyle = {
  fontFamily: 'Goudy Old Style',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
};

interface User {
  id:any;
  name:string;
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}

interface Image {
  // ... image data structure from Strapi (if applicable)
  id: number;
  url: string;
}

interface Nation {
  id: number;
  name: string;
}
function IndividualDetails() {
  const { id } = useParams();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [individual, setIndividual] = useState<Individual | null>(null);
  const storedUser = localStorage.getItem('user');
    const loggedInUser = storedUser ? JSON.parse(storedUser) as User : null;
  const [nationList, setNationList] = useState<any[]>([]);
  const[participants,setParticipants]=useState<any[]>([])
  const [userList, setUserList] = useState<any[]>([]);
  const[summits,setSummits]=useState<any[]>([])
  const [associationList, setAssociationList] = useState<Nation[]>([]);
  const [subList, setSubList] = useState<any[]>([]);
  const [regionList, setRegionList] = useState<any[]>([]);
  const [filteredImage, setFilteredImage] = useState<string | null>(null);

  let [loading, setLoading] = useState(true);
  const darkMode=useDarkMode()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const nationResponse = await fetch('https://api.upfafrica.org/admin/association/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['id', 'name'],
              paginate: 166,
            },
            isCountOnly: false,
          }),
        });
        const nationData = await nationResponse.json();
        if (nationData?.data) {
          setAssociationList(nationData.data.data);
        } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
        }
      } catch (error) {
        console.error('Error fetching nation data:', error);
      }
    };
    fetchData();
  }, []);
  const GetAllUsers = async (): Promise<any> => {
    try {
      const apiUrl = 'https://api.upfafrica.org/admin/user/list';
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Add this line for CORS
          'Authorization': `Bearer ${token}` // Include 'Bearer' before the token
        },
        body: JSON.stringify({
          "query": {},
          "options": {
            "select": [
              "id",
              "name",
              "regionId",
              "subRegionId",
              "ambassadorStatus",
              "email",
              "upfPosition",
              "nationality",
              "primaryTag",
              "leadershipStatus",
              "username",
              "picture"
            ],
            "paginate": 500000

          },
          "isCountOnly": false
        }),
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed. ');
      }

      const userData = await response.json();
      if (userData?.data) {
        setUserList(userData.data.data);
        setIsDataFetched(true); // Set the loading state
      } else {
        console.error('Invalid User data:', userList);
        throw new Error('Invalid User data.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };;
  useEffect(() => {
    GetAllUsers();
},[]);

console.log("summits",summits)
  const getAssociationNameById = (id: any) => {
    if (!id || !Array.isArray(associationList) || associationList.length === 0) {
        return '';
    }
    const associate = associationList.find(n => n.id === parseInt(id));
    return associate ? associate.name : '';
};

  useEffect(() => {
    const fetchIndividual = async () => {
      try {
        const apiUrl = `https://api.upfafrica.org/admin/user/${id}`;
        const token = localStorage.getItem('token');
        const response = await fetch(
          apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*', // Add this line for CORS
                'Authorization': `Bearer ${token}` // Include 'Bearer' before the token
            }
          }
        );
        const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "query": {},
                        "options": {
                            "select": [
                                "id",
                                "name",
                            ],
                            "paginate": 166
                        },
                        "isCountOnly": false
                    }),
                });
                const nationData = await nationResponse.json();
                if (nationData?.data) {
                    setNationList(nationData.data.data);
                } else {
                    console.error('Invalid nation data:', nationData);
                    throw new Error('Invalid nation data.');
                }
                const regionResponse = await fetch('https://api.upfafrica.org/admin/region/list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "query": {},
                        "options": {
                            "select": [
                                "id",
                                "name",
                            ],
                            "paginate": 166
                        },
                        "isCountOnly": false
                    }),
                });
                const regionData = await regionResponse.json();
                if (regionData?.data) {
                    setRegionList(regionData.data.data);
                } else {
                    console.error('Invalid nation data:', regionData);
                    throw new Error('Invalid nation data.');
                }
                const SubResponse = await fetch('https://api.upfafrica.org/admin/subregion/list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "query": {},
                        "options": {
                            "select": [
                                "id",
                                "name",
                            ],
                            "paginate": 166
                        },
                        "isCountOnly": false
                    }),
                });
                const SubregionData = await SubResponse.json();
                if (SubregionData?.data) {
                    setSubList(SubregionData.data.data);
                    console.log(subList)
                } else {
                    console.error('Invalid nation data:', nationData);
                    throw new Error('Invalid nation data.');
                }
        if (!response.ok) {
          throw new Error('Failed to fetch individual data');
        }
        const data = await response.json();
        setIndividual(data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchIndividual();
  }, [id]);

    // Strapi image fetching (if applicable)
    useEffect(() => {
      if (!individual?.picture) return; // Avoid unnecessary Strapi requests
  
      const fetchImage = async () => {
        try {
          const strapiImageUrl = `https://fm.upfafrica.org${individual.picture}`; // Adjust endpoint and URL structure based on Strapi
          const response = await axios.get<Image>(strapiImageUrl, {
            headers: {
              'Content-Type': 'multipart/form-data', // Adjust if Strapi requires specific headers for image retrieval
              Authorization: `Bearer ${"bc52ef2907c0212c0b06ebb5edf27cd615c242bfd7027050e393891120c9116a63a2dff3c4b4cc206bc8f06608c9f32a5e1c4acb854b11dc85530a5def61a2a6986d782bf2ffc725a41ca639573a6f70174f954a6ef6568717b58eb7d200b266f5987ab199446b8fcc9d064f1f6a645e591dce727a7489d817df67fcd1ded0fd"}` // Replace with your Strapi token
            }
          });
           
          setFilteredImage(response.data.url);
          setFilteredImage(strapiImageUrl);
          console.log('Filtered Image Url:', strapiImageUrl);
          
        } catch (error) {
          console.error('Error fetching image from Strapi:', error);
        }
      };
  
      fetchImage();
    }, [individual]);

    const displayImage = () => {
      if (!filteredImage) return null;
      console.log('Filtered Image :', filteredImage);
  
      return (
        <img
          src={filteredImage} // Use Strapi image URL from filteredImage
          alt={individual?.name || 'Image'} // Optional: Use user name for accessibility
          className="lg:w-1/4 lg:h-1/4 object-cover rounded-lg mb-4 containerr"
        />
      
      );
    };

  const getAllActivities = async () => {
    try {
      const apiUrl = 'https://api.upfafrica.org/admin/activity/list';
      const token = localStorage.getItem('token');
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "query": {
             "isDeleted": false,
          },
          "options": {
              "select": [
                  "id",
                  "name",
                  "coverImage",
                  "host",
                  "participantsId",
                  "date",
                  "theme",
                  "status",
                  "nationId",
                  "isDeleted"
              ],
              "paginate": 3000
          },
          "isCountOnly": false
        }),
      });
  
      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }
  
      const ActivitiesData = await response.json();
      if (Array.isArray(ActivitiesData.data.data)) {
        setSummits(ActivitiesData?.data?.data?.filter((item: any) => item?.status === 'Approved'));
        setParticipants( summits.filter((activity: any) =>
            activity.participantsId?.includes(individual?.id)
          
          ));
         
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  console.log("summits",summits)
  console.log("Participants",participants)
  useEffect(() => {
    
    getAllActivities()
  }, []);
  if (!individual) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
        <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    );
    
  }
  
  const getNationNameById = (id: any) => {
    if (!id || !Array.isArray(nationList) || nationList.length === 0) {
        return '';
    }
    const nation = nationList.find(n => n.id === id);
    return nation ? nation.name : '';
};
const getSubNameById = (id: any) => {
  if (!id || !Array.isArray(subList) || subList.length === 0) {
      return '';
  }
  const Sub = subList.find(n => n.id === id);
  return Sub ? Sub.name : '';
};
const getRegionNameById = (id: any) => {
  if (!id || !Array.isArray(regionList) || regionList.length === 0) {
      return '';
  }
  const Region= regionList.find(n => n.id === id);
  return Region ? Region.name : '';
};
console.log(userList)
const getUpdateNameById = (id:any) => {
  if (!id || !isDataFetched || !Array.isArray(userList)|| userList.length === 0) {
    return '';
  }
  console.log('ID:', id);
  const user = userList.find((user) => user.id === id);
  console.log('User:', user);
  return user ? user.name || user.username : '';
};

return (
  <div>
    {individual ? (
      <div className="">
        
        <h2 style={{ ...NameStyle, fontSize: "24px" }} className="mb-8">{individual.name}</h2>
        <div className="p-5">

        {displayImage()}
        </div>
        <div className="flex flex-row justify-between bg-gray-200 rounded-lg p-4 lg:w-3/4 h-3/4 mb-4 containerr" style={TitleStyle}>
          <div className="flex flex-col justify-between">
          <p className="mb-4">Full Name: {individual.name}</p>
          <p className="mb-4">Region: {individual?.regionId && getRegionNameById(individual?.regionId)}</p>
          <p className="mb-4">Sub-region: {individual?.subRegionId && getSubNameById(individual?.subRegionId)}</p>
          <p className="mb-4">Nation: {individual?.nationality && getNationNameById(individual?.nationality)}</p>
          <p className="mb-4">Family Name: {individual.name.split(' ')[1]}</p>
          <p className="mb-4">Given Name: {individual.name.split(' ')[0]}</p>
          <p className="mb-4">Gender: {individual.gender}</p>
          <p className="mb-4">Email: {individual.email}</p>
          <p className="mb-4">UPF position:{((individual.position || individual.upfPosition) && (individual.position || individual.upfPosition).split(' ').slice(0, 6).join(' '))}</p>
          <p className="mb-4">Biography/CV: {individual.biography}</p>
          <p className="mb-4">Religion: {individual.religion}</p>
          <p className="mb-4">Organization: {individual.primaryTag && getAssociationNameById(individual?.primaryTag)}</p>
          <p className="mb-4">Honorifics:{individual.honorifics}</p>
          <div >
            <h3 className="TitleStyle">Summits Participated:</h3>
            {participants.length > 0 ? (
              <ol className='list-decimal pl-20 bg-green-300 w-96 containerr'>
                {participants.map((activity: any) =>{ 
                   const summitDate=new Date(activity.date)
                return (
                  <li key={activity.id}>
                    <strong>{activity.name}</strong> - {summitDate.toLocaleDateString()}
                  </li>
                )})}
              </ol>
            ) : (
              <p>No summits participated.</p>
            )}
          </div>
          </div>
          {isDataFetched && (
            <div className="bg-blue-900 bg-opacity-20 p-3 border h-fit rounded-md">
      <p className={`mb-4 text-red-700 ${darkMode?'text-white':''}`} >Last UpdatedBy: {(individual?.updatedBy && getUpdateNameById(individual?.updatedBy))||(individual?.updatedBy===loggedInUser?.id && loggedInUser?.name)||"Not Updated Yet"}</p>
      </div>
    )}
        </div>
        
      </div>
    ) : (
      <div className="sweet-loading">
        <button onClick={() => setLoading(!loading)}>Toggle Loader</button>

        <FadeLoader
        />
      </div>
    )}
  </div>
);

}

interface ser {
  userType: number;
 subRegionId:string;
 nationality:string
}

function LeadershipGrid() {
  const [leadersData, setLeadersData] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const storedUser = localStorage.getItem('user');
  const [debouncedFilterValue] = useDebounce(filterValue, 300);
  const user = storedUser ? JSON.parse(storedUser) : null;
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const [sortKey, setSortKey] = useState<string>('name'); // Default sorting key
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sorting order
  const [loading, setLoading] = useState(true);
  

  const GetAllUsers = async (): Promise<any> => {
    try {
      const apiUrl = 'https://api.upfafrica.org/admin/user/list';
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Add this line for CORS
          'Authorization': `Bearer ${token}` // Include 'Bearer' before the token
        },
        body: JSON.stringify({
          "query": {},
          "options": {
            "select": [
              "id",
              "name",
              "regionId",
              "subRegionId",
              "ambassadorStatus",
              "email",
              "upfPosition",
              "nationality",
              "primaryTag",
              "leadershipStatus",
              "username",
              "picture"
            ],
            "paginate": 50000,
            "sort": {
              [sortKey]: sortOrder === 'asc' ? 1 : -1
            }
          },
          "isCountOnly": false
        }),
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed. ');
      }

      const userData = await response.json();

      if (userData?.data) {
        setLeadersData(userData?.data?.data?.filter((item: any) => {
          const subRegionCheck = item?.subRegionId === user?.subRegionId;
          const nationalityCheck = item?.nationality === user?.nationality;
          const leadershipStatusCheck = item?.leadershipStatus === true;
         if (user?.userType === 8) {
          return true && leadershipStatusCheck && (
              (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) 
          );
      } else if (user?.userType === 5 || user?.userType === 6 || user?.userType === 3)  {
          return true && nationalityCheck && leadershipStatusCheck &&
              (
                  (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                  (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase()))
              );
          } else if (user?.userType === 2 || user?.userType === 7)  {
              return true && subRegionCheck && leadershipStatusCheck &&
                  (
                      (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) 
                  );
                  
          
      } else {
          return false
      }
      }));
      
        console.log('User data:', userData.data);
      } else {
        console.error('Invalid user data:', userData);
        throw new Error('Invalid user data.');
      }

      setLoading(false); // Set loading to false when data is fetched

      return userData;
    } catch (error) {
      console.error('Error:', error);
      setLoading(false); // Set loading to false when there's an error
      return error;
    }
  };
  useEffect(() => {
    console.log('Debounced Filter Value:', debouncedFilterValue);
    GetAllUsers();
}, [debouncedFilterValue]);

  useEffect(() => {
    GetAllUsers();
  }, [sortKey, sortOrder]); // Call GetAllUsers when sortKey or sortOrder changes
 

  const deleteUser = async (id: any) => {
    try {
      const apiUrl = `https://api.upfafrica.org/admin/user/delete/${id}`;
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }

      const responseData = await response.json();
      console.log('User deleted successfully:', responseData);

      // Display a success notification
      notification.open({
        message: 'User Deleted',
        description: 'The user has been deleted successfully.',
        duration: 3, // Duration in seconds
      });

      GetAllUsers();

      return responseData;
    } catch (error) {
      console.error('Error:', error);

      // Display an error notification
      notification.error({
        message: 'Error Deleting User',
        description: 'An error occurred while deleting the user.',
        duration: 3,
      });

      return error;
    }
  };

  const showModal = (id: any) => {
    setUserIdToDelete(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteUser(userIdToDelete);
      setIsModalVisible(false);
    } catch (error) {
      // Handle errors if needed
    }
  };

  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/Leadershipinput");
  };

  const handleEdit = (id: any) => {
    navigate(`/EditAmbassador/${id}`);
  };

  const TitleStyle = {
    color: '#435782',
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  };
  const paginatedLeadersData = leadersData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page: number) => {
      setCurrentPage(page);
  };
  const toTitleCase = (str: any) => {
    if (str == null) {
        return ''; 
    }
    return str.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
  return (
    <div className=" w-[100%]">
      <div className="flex flex-row justify-between mt-6">
        {user && user.userType === 8 && (
          <button
            onClick={handleNext}
            className="w-2/12 h-10 mr-3 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr "
          >
            + Add
          </button>
        )}
         <Input
                                placeholder="Search by name, email, position, or nationality"
                                value={filterValue}
                                className='text-black containerrr'
                                onChange={(e) => setFilterValue(e.target.value)}
                                style={{ width: 300 }}
                            />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
          </div>
        </div>
      ) : (
        leadersData?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-12">
            {paginatedLeadersData?.map((item, id) => (
              <div key={id} className="bg-gray-200 rounded-lg p-4 w-80 h-96 containerrr overflow-hidden">
                <img
                  src={`${item.picture ? `https://fm.upfafrica.org${item.picture}` : '/default-profile-image.png'}`} // Assuming default image is in public folder
                  alt={`Profile of ${item.name}`}
                  className="w-full h-3/4 object-cover rounded-lg mb-4"
                />

                <div style={{ ...TitleStyle, fontSize: "24px" }} className="mt-10  flex justify-between items-center">
                  <Link to={`/individual/${item?.id}`} className="containerrr ">{toTitleCase(item.name)}</Link>
                  {(user?.userType === 8 || user?.userType === 4 || user?.userType === 7 || user?.userType === 6) && (
                    <div className="flex flex-row">
                      <FontAwesomeIcon onClick={() => showModal(item?.id)} className="cursor-pointer  w-5 h-5" color="#FF0000" icon={faTrash} />
                      <CiEdit className="cursor-pointer ml-3" onClick={() => handleEdit(item?.id)} />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <Modal
              title="Delete User"
              visible={isModalVisible}
              onOk={handleDelete}
              onCancel={() => setIsModalVisible(false)}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
            >
              <p>Are you sure you want to delete this user?</p>
            </Modal>
          </div>
        ) : (
          <div className="min-h-screen flex flex-col justify-center items-center px-4">
            <div className="text-center max-w-lg">
              <FontAwesomeIcon icon={faLock} size="6x" className="text-red-600 mb-4" />
              <h1 className="text-3xl font-bold mb-2">Not Enough Permissions or No Data Exists</h1>
              <p className="mb-6">
                Please contact Superiors to confirm your Authorizations Limits
              </p>
            </div>
          </div>
        )
      )}
      <Pagination
                       current={currentPage}
                       pageSize={itemsPerPage}
                       total={leadersData.length}
                       onChange={handlePageChange}
                       style={{ textAlign: 'center', marginTop: '20px',marginBottom: '20px'  }}
                    />
    </div>
  );
}



export { IndividualDetails, LeadershipGrid };