import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../App.css';
import logo from '../images/upflogo_with_typeandacronym-rv0602-2011-1080p-01 1.png';
import { RxHamburgerMenu } from "react-icons/rx";
import { useDarkMode } from '../../contextApis/DarkModeContext';

interface SidebarProps {
  setSelectedMenu: React.Dispatch<React.SetStateAction<any>>;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  menuItems: {
    colorClass: any; to: string; icon: string; text: string 
  }[];
}
const Sidebar: React.FC<SidebarProps> = ({ setSelectedMenu, menuItems }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const { darkMode } = useDarkMode();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleItemClick = (index: any) => {
    setSelectedItem(index);
    setSelectedMenu(menuItems[index].text);
  };

  const TitleStyle = {
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  };

  return (
    <aside className="p-4">
       <div className="flex ">
      <div className='flex flex-row  lg:w-40'>
        <img src={logo} alt="Logo" />
        <h2 className='mt-2' style={TitleStyle}>UPF AFRICA</h2>
       
      </div>
      <div className="cursor-pointer items-center mt-2" style={{ marginLeft: '100px' }} onClick={toggleSidebar}>
          <RxHamburgerMenu className={`h-6 w-6 `} />
        </div>
        </div>
      {isSidebarOpen && (
        <div className="mt-8">
          <div className="text-black">
            <ul className="list-none p-0">
              {menuItems.map((menuItem:any, index) => (
                <li
                  key={index}
                  className={`flex items-center mb-6 mr-2 cursor-pointer gap-1   ${selectedItem === index ? 'border-radius-lg shadow-md containerr ' : ''} hover:shadow-lg hover:border hover:border-blue-500 hover:rounded-lg hover:bg-blue-300  ${darkMode?'hover:bg-slate-600':''}`}
                  onClick={() => handleItemClick(index)}
                >
                  <Link to={menuItem.to} className={`flex items-center  mr-2 cursor-pointer px-1 py-2 gap-1  ${selectedItem === index ? '' : ''}`} onClick={() => handleItemClick(index)}>
                    <div className='mr-3'>
                      {React.cloneElement(menuItem.icon, { className: `${menuItem.colorClass} ` })}
                    </div>
                    <h2
                    className='flex flex-row justify-between'
                      style={{
                        
                        fontFamily: 'Goudy Old Style',
                        fontSize: '17px',
                        fontWeight: 600,
                      }}
                    >
                      {menuItem.text}
                    </h2>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;
