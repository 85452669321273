import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Empty, Modal,  notification, Input, Button } from 'antd';
import image1 from '../images/1.png';
import image2 from '../images/2.png';
import image3 from '../images/3.png';
import image4 from '../images/4.png';
import image5 from '../images/5.png';
import MyChartComponent from './barGraph'
import { useTranslation } from 'react-i18next';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { useDebounce } from 'use-debounce';
import { useNavigate } from "react-router-dom";
import { Select ,Pagination} from 'antd';
import { FadeLoader } from "react-spinners";
import { TiCancel } from "react-icons/ti";
const { Option } = Select;

interface User {
    userType: number;
    subRegionId:number;
    regionId:number;
    nationality:number;
  }
const MainPage: React.FC = () => {
   

    const [subregionCounts, setSubregionCounts] = useState<any>({
        11: 0, // SR Central
        12: 0, // SR East
        13: 0, // SR North
        14: 0, // SR South
        15: 0, // SR West
      });
      
    // const pageSize: number = 15; // Number of contacts per page
    const [usersData, setUsersData] = useState<any[]>([]);
    const [massDelete, setMassDelete] = useState<any[]>([]);
    const [massFilter, setMassFilter] = useState<any[]>([]);
    const [subFilter, setSubFilter] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [isMassDeleteModalVisible, setIsMassDeleteModalVisible] = useState(false);
    const [nationList, setNationList] = useState<any[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [displayDelete, setDisplayDelete] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false); 
    const [subRegionList,setSubRegionList]=useState<any[]>([])
    const [issubRegionDropdownVisible, setIssubRegionDropdownVisible] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [debouncedFilterValue] = useDebounce(filterValue, 300);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const { t } = useTranslation();
//     const isMassFilterEmpty = massFilter.length === 0;
// const isNationalityIncluded = item?.nationality && massFilter.includes(getNationNameById(item.nationality));
// console.log("isMassFilterEmpty:", isMassFilterEmpty);
// console.log("isNationalityIncluded:", isNationalityIncluded);
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();
    
    const [sortKey, setSortKey] = useState<string>('name'); // Default sorting key
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sorting order
    const token = localStorage.getItem('token');
    console.log(massFilter)
    console.log(subFilter)
    useEffect(() => {
        if (statusFilter === 'Nation') {
          setIsDropdownVisible(true);
        } else {
          setIsDropdownVisible(false);
        }
      }, [statusFilter]);
      useEffect(() => {
        if (statusFilter === 'Sub-Region') {
          setIssubRegionDropdownVisible(true);
        } else {
          setIssubRegionDropdownVisible(false);
        }
      }, [statusFilter]);
    useEffect(() => {
        // Check if the user is logged in (e.g., by checking the token)
        if (!token) {
            navigate('/login');
        } 
      }, []);
      function getPerPage() {
        const largeScreen = window.matchMedia("(min-width: 720px)").matches;
        return largeScreen ? 50 : 10;
      }
      const itemsPerPage=getPerPage()
    const GetAllsubregions = async (): Promise<any> => {
        try {
            const apiUrl = 'https://api.upfafrica.org/admin/user/list';
            const token = localStorage.getItem('token');
    
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "query": {},
                    "options": {
                        "select": [
                            "id",
                            "name",
                            "position",
                            "subRegionId",
                            "upfPosition",
                            "nationality"
                        ],
                        "paginate": 50000
                    },
                    "isCountOnly": false
                }),
            });
    
            if (!response.ok) {
                console.error('Request failed:', response.status, response.statusText);
                throw new Error('Request failed.');
            }
    
            const regionData = await response.json();
            
            if (regionData?.data) {
                // Calculate the number of individuals per sub-region
                const subregionCounts: { [key: number]: number } = {
                    15: 0, // SRWEST
                    12: 0, // SREAST
                    13: 0, // SRNORTH
                    14: 0, // SRSOUTH
                    11: 0,
                };
    
                regionData.data.data.forEach((user: any) => {
                    const subRegionId = user.subRegionId;
                    if (subRegionId !== null) {
                        switch (subRegionId) {
                            case 11: // SRCENTRAL
                                subregionCounts[11]++;
                                break;
                            case 12: // SREAST
                                subregionCounts[12]++;
                                break;
                            case 13: // SRNORTH
                                subregionCounts[13]++;
                                break;
                            case 14: // SRSOUTH
                                subregionCounts[14]++;
                                break;
                            case 15: // SRWEST
                                subregionCounts[15]++;
                                break;
                            default:
                                console.error('Invalid subRegionId:', subRegionId);
                                break;
                        }
                        
                    }
                    console.log(`SubRegionId: ${subRegionId}, Counts: `, subregionCounts);
                });
    
                
                setSubregionCounts(subregionCounts);
            }
        } catch (error) {
            console.error('Error:', error);
            return error;
        }
    }
    
    

    const GetAllUsers = async (page: number): Promise<any> => {
        try {
            const apiUrl = 'https://api.upfafrica.org/admin/user/list';
            const token = localStorage.getItem('token');

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "query": {},
                    "options": {
                        "select": [
                            "id",
                            "name",
                            "position",
                            "subRegionId",
                            "upfPosition",
                            "userType",
                            "organisation",
                            "nationality",
                            "leadershipStatus",
                            "ambassadorStatus"
                        ],
                        "paginate": 50000,
                        "sort": {
                            [sortKey]: sortOrder === 'asc' ? 1 : -1
                        }
                    },
                    "isCountOnly": false
                }),
            });

            if (!response.ok) {
                console.error('Request failed:', response.status, response.statusText);
                throw new Error('Request failed.');
            }

            const userData = await response.json();

            setUsersData(userData?.data?.data?.filter((item:any) => {
                const statusCheck = statusFilter === 'leadership' ? item.leadershipStatus : statusFilter === 'ambassador' ? item.ambassadorStatus : true;
                return statusCheck && item?.subRegionId === User?.subRegionId && (item.leadershipStatus=== true||item.ambassadorStatus ===true);
            }));
   console.log(massFilter)
            if (Array.isArray(userData.data.data)) {
                setUsersData(userData?.data?.data?.filter((item: any) => {
                    const userTypeCheck = item?.userType === 8 || item?.userType === 5;
                    const subRegionCheck = item?.subRegionId === User?.subRegionId;
                    const nationalCheck = (massFilter.length === 0) || (item?.nationality && massFilter.includes(getNationNameById(item.nationality)));
                    const subCheck = (subFilter.length === 0) || (item?.subRegionId && subFilter.includes(getSubRegionNameById(item.subRegionId)));
                    const nationalityCheck = item?.nationality === User?.nationality;
                    const statusCheck = statusFilter === 'leadership' ? item.leadershipStatus : statusFilter === 'ambassador' ? item.ambassadorStatus: statusFilter === 'Contacts' ?(!item.ambassadorStatus && !item.leadershipStatus): true;
                    
                    if (User?.userType === 8) {
                        return statusCheck && nationalCheck && subCheck &&(
                            (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.subRegionId && getSubRegionNameById(item.subRegionId).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
                        );
                    }
                    else if (User?.userType === 5 || User?.userType === 6 || User?.userType === 3)  {
                        return statusCheck && nationalityCheck && (
                            (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
                        );
                    } else if (User?.userType === 2 || User?.userType === 7)  {
                        return statusCheck && subRegionCheck && (
                            (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                            (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
                        );
                    } else {
                        return false;
                    }
                }));
            } else {
                console.error('Invalid user data format:', userData);
                throw new Error('Invalid user data format.');
            }

            return userData;
        } catch (error) {
            console.error('Error:', error);
            return error;
        }
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
          }, 20000);
          GetAllUsers(currentPage);
          return () => clearTimeout(timer);
        
    }, [debouncedFilterValue, statusFilter,massFilter,subFilter]);
    
    const deleteUser = async (id: any) => {
        try {
            const apiUrl = `https://api.upfafrica.org/admin/user/delete/${id}`;
            const token = localStorage.getItem('token');

            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                console.error('Request failed:', response.status, response.statusText);
                throw new Error('Request failed.');
            }

            const responseData = await response.json();
            console.log('User deleted successfully:', responseData);

            // Display a success notification
            notification.open({
                message: 'User Deleted',
                description: 'The user has been deleted successfully.',
                duration: 3, // Duration in seconds
            });
         
            GetAllUsers(currentPage);

            return responseData;
        } catch (error) {
            console.error('Error:', error);

            // Display an error notification
            notification.error({
                message: 'Error Deleting User',
                description: 'An error occurred while deleting the user.',
                duration: 3,
            });

            return error;
        }
    };

    const showModal = (id: any) => {
        setUserIdToDelete(id);
        setIsModalVisible(true);
    };
    
    
    const handleEdit = (id: any) => {
        navigate(`/EditAmbassador/${id}`);
    };

    const handleDelete = async () => {
        try {
            await deleteUser(userIdToDelete);
            setIsModalVisible(false);
        } catch (error) {
            // Handle error
        }
    };
//     const handlePageChange = (page: number) => {
//       setCurrentPage(page);
//       GetAllUsers(page);
//   };
    
const getSubRegionNameById = (id: any) => {
    if (!id || !Array.isArray(subRegionList) ||subRegionList.length === 0) {
        return '';
    }
    const nation = subRegionList.find(n => n.id === id);
    return nation ? nation.name : '';
};

    const getNationNameById = (id: any) => {
        if (!id || !Array.isArray(nationList) || nationList.length === 0) {
            return '';
        }
        const nation = nationList.find(n => n.id === id);
        return nation ? nation.name : '';
    };
    const darkModeClass = darkMode ? 'dark' : '';
    const subRegionData = async () => {
        await GetAllsubregions();
          await GetAllUsers(currentPage); // Pass currentPage here
          const token = localStorage.getItem('token');
          const response = await fetch('https://api.upfafrica.org/admin/subregion/list', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                  "query": {},
                  "options": {
                      "select": [
                          "id",
                          "name",
                      ],
                      "paginate": 366
                  },
                  "isCountOnly": false
              }),
          });
          const subRegionData = await response.json();
          if (subRegionData?.data) {
              setSubRegionList(subRegionData.data.data);
          } else {
              console.error('Invalid nation data:', subRegionData);
              throw new Error('Invalid nation data.');
          }
      };
  
    useEffect(() => {
        subRegionData()
      const fetchData = async () => {
        await GetAllsubregions();
          await GetAllUsers(currentPage); // Pass currentPage here
          const token = localStorage.getItem('token');
          const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                  "query": {},
                  "options": {
                      "select": [
                          "id",
                          "name",
                      ],
                      "paginate": 166
                  },
                  "isCountOnly": false
              }),
          });
          const nationData = await nationResponse.json();
          if (nationData?.data) {
              setNationList(nationData.data.data);
          } else {
              console.error('Invalid nation data:', nationData);
              throw new Error('Invalid nation data.');
          }
      };
      fetchData();
  }, [currentPage]);
  const deleteMassUser = async () => {
    try {
        const apiUrl = `https://api.upfafrica.org/admin/user/deleteMany`;
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                ids: massDelete // Send the array of user IDs to be deleted
            }),
        });

        if (!response.ok) {
            console.error('Request failed:', response.status, response.statusText);
            throw new Error('Request failed.');
        }

        const responseData = await response.json();
        console.log('Users deleted successfully:', responseData);

        notification.open({
            message: 'Users Deleted',
            description: 'The users have been deleted successfully.',
            duration: 3,
        });

        GetAllUsers(currentPage); 

        return responseData;
    } catch (error) {
        console.error('Error:', error);

        notification.error({
            message: 'Error Deleting Users',
            description: 'An error occurred while deleting the users.',
            duration: 3,
        });

        return error;
    }
};
  const showMassDeleteModal = () => {
    setIsMassDeleteModalVisible(true);
};
  const handleMassDelete = async () => {
    try {
        await deleteMassUser();
        setIsMassDeleteModalVisible(false);
    } catch (error) {
        // Handle error if needed
    }
};
  const handleCheckboxChange = (id: any) => {
    setMassDelete((prev) => {
        if (prev.includes(id)) {
            return prev.filter((ambassadorId) => ambassadorId !== id);
        } else {
            return [...prev, id];
        }
    });
};
const handleFilterCheckboxChange = (nationName: string) => {
    if (massFilter.includes(nationName)) {
        setMassFilter(massFilter.filter((name) => name !== nationName));
    } else {
        setMassFilter([...massFilter, nationName]);
    }
};
const handleSubCheckboxChange = (subName: string) => {
    if (subFilter.includes(subName)) {
        setMassFilter(subFilter.filter((name) => name !== subName));
    } else {
        setMassFilter([...subFilter, subName]);
    }
};
  const handleMany=()=>{
    setMassDelete([])
    setIsModalVisible(false);
    setDisplayDelete(true)
}
  const handlePageChange=(page:number)=>{
    setCurrentPage(page)
  }
const paginatedData=usersData.slice((currentPage-1)*itemsPerPage,currentPage*itemsPerPage)
  const cardsData = [
    { id: 1, imageUrl: image1, text1: subregionCounts[13] || '0', text2: 'SR North', to: '/sr-north' },
    { id: 2, imageUrl: image2, text1: subregionCounts[15] || '0', text2: 'SR West', to: '/sr-west' },
    { id: 3, imageUrl: image3, text1: subregionCounts[11] || '0', text2: 'SR Central', to: '/sr-central' },
    { id: 4, imageUrl: image4, text1: subregionCounts[14] || '0', text2: 'SR South', to: '/sr-south' },
    { id: 5, imageUrl: image5, text1: subregionCounts[12] || '0', text2: 'SR East', to: '/sr-east' },
];
const isEmpty = Array.isArray(usersData) && usersData.length === 0;
const isEmptyNation = Array.isArray(nationList) && nationList.length === 0;
const toTitleCase = (str: any) => {
    if (str == null) {
        return ''; 
    }
    return str.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

if (isEmpty && isEmptyNation && (User?.userType === 8 || (User?.userType === 2 && User?.regionId === 1))) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
}
  return (
    <>
        {User?.userType === 8 && (
            <>
       
        <div className="p-6" >
        <div className='flex gap-2 p-7 text-lg containerr rounded-lg overflow-hidden shadow-lg bg-white w-[95%] ml-5 '>
        <p className="text-2xl font-semibold">{t('Total')} : {(subregionCounts[11]+subregionCounts[12]+subregionCounts[13]+subregionCounts[14]+subregionCounts[15]).toLocaleString()||0}</p>
    </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-12  containerr rounded-lg overflow-hidden shadow-lg bg-white w-[95%] ml-5 mt-6">
            
            {cardsData.map((card) => (
    <Link key={card.id} to={card.to || '#'}>
        <div className="flex-shrink-0 w-full ml-4 containerrr">
            <div className="shadow-lg rounded-lg overflow-hidden flex items-center p-2 space-x-4 sm:space-x-8 w-full containerr " style={{ background: '#1E71B8' }}>
                <img src={card.imageUrl} alt={`Card ${card.id}`} className="w-10 h-10 bg-white rounded-full" />
                <div className="p-1 text-white">
                    <p className="text-lg font-semibold">{card.text1}</p>
                    <p className='text2xl font-semibold'>{t (card.text2)}</p>
                </div>
            </div>
        </div>
    </Link>
))}
            </div>

            <div className='p-4'>
            </div>
            <div className="containerr rounded-lg overflow-hidden shadow-lg bg-white flex ml-5 w-[95%] ">
                <div className="  w-full  justify-center">
  <p className="text-2xl font-semibold p-3">{t('Contacts per Sub-Region')} </p>
                <MyChartComponent subregionCounts={subregionCounts} darkMode={darkMode}/>
                </div>
            </div>
            <div className="containerr  rounded-lg overflow-hidden w-[95%] shadow-lg bg-white flex  ml-5 mt-8 ">
                <div className="container mx-auto p-8">
                    <div className="px-6 py-4 w-full">
                        <div className='flex flex-row justify-between mb-7'>
                        <p className="font-bold text-xl mb-2" >Individuals</p>
                        <div>
                        <Select
    defaultValue=""
    className="w-40"
    style={{  marginRight: 16 }}
    onChange={(value) => setStatusFilter(value)}
>
    <Option value="">{t('All Status')}</Option>
    <Option value="leadership">{t('Leadership')}</Option>
    <Option value="ambassador">{t('Ambassador')}</Option>
    <Option value="Contacts">Contacts</Option>
    <Option value="Nation">{t('Nation')}</Option>
    <Option value="Sub-Region">{t('Sub-Region')}</Option>
</Select>
{issubRegionDropdownVisible?(
   <Select
    mode="multiple"
   placeholder="Select 
   sub_region"
   className="w-64"
   onChange={(sub) => setSubFilter(sub)}
   value={subFilter}
   >
     {subRegionList.map(sub => (
        <Option key={sub.id} value={sub.name}>
        <div className="flex justify-between items-center">
            <span>{sub.name}</span>
            <input
                type="checkbox"
                checked={subFilter.includes(sub?.name)}
                onChange={() => handleSubCheckboxChange(sub?.name)}
            />
        </div>
    </Option>
     ))}
   </Select>
):(
    <Input
    placeholder="Search by name, email, position, or nationality"
    value={filterValue}
    onChange={(e) => setFilterValue(e.target.value)}
    style={{ width: 300 }}
    className="containerrr"
/>
)
}

</div>
{displayDelete===true && <div className="mb-4 flex flex-row gap-3">
           
           <button onClick={showMassDeleteModal} className="bg-red-500 text-white py-2 px-4 rounded">
               <FontAwesomeIcon icon={faTrash} className="mr-1" />
           </button>
           <button onClick={()=>setDisplayDelete(false)} className="bg-blue-500 text-white py-2 px-3 rounded  flex justify-center">
               <TiCancel className="w-6 h-6  " />
           </button>
   
       </div>}

                        </div>
                      <table className="w-full">
                        <thead className='text-left'>
                            <tr>
                                <th className="py-2">{t('NO')}</th>
                                <th className="py-2 px-3">{t('Name')}</th>
                                <th className="py-2">{t('Position')}</th>
                                <th className="py-2">
                                {isDropdownVisible ? (
    <Select
        mode="multiple"
        placeholder="Select Nationality"
        className="w-40"
        onChange={(values) => setMassFilter(values)}
        value={massFilter}
    >
        {nationList.map((nation) => (
            <Option key={nation.id} value={nation.name}>
                <div className="flex justify-between items-center">
                    <span>{nation.name}</span>
                    <input
                        type="checkbox"
                        checked={massFilter.includes(nation?.name)}
                        onChange={() => handleFilterCheckboxChange(nation?.name)}
                    />
                </div>
            </Option>
        ))}
    </Select>
) : 
<span>Nationality</span>
}

                </th>
                                {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) &&<th className='py-2'>Action</th>}
                            </tr>
                        </thead>
                        {paginatedData?.length > 0 ? (
                            <tbody>
                                {paginatedData.map((user, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-1">{(currentPage-1)*itemsPerPage+index + 1}</td>
                                        <td className="py-2 px-3">{toTitleCase(user.name)}</td>
                                        <td className="py-2 w-2/5">{toTitleCase((user.position || user.upfPosition) && (user.position || user.upfPosition).split(' ').slice(0, 120).join(' '))}
</td>
                                        <td className="py-2 w-1/4">{user.nationality && t(`${getNationNameById(user.nationality)}`)}</td>
                                        {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && (
                                           <td className="py-2 flex flex-row">
                                           {displayDelete===false &&  <FontAwesomeIcon
                                               onClick={() => showModal(user?.id)}
                                               className="cursor-pointer "
                                               color="#FF0000"
                                               icon={faTrash}
                                           />
}
                                           <button
                               className="text-blue-500 mr-4 ml-2"
                               onClick={() => navigate(`/editAmbassador/${user?.id}`)}
                           >
                               <CiEdit size={20} />
                           </button>
                         {displayDelete===true &&<input
                               type="checkbox"
                               checked={massDelete.includes(user?.id)}
                               onChange={() => handleCheckboxChange(user?.id)}
                           />}
                                       </td>
                                   )}
                               </tr>
                           ))}
                            </tbody>
                        ) : <Empty description="No Data Found" />}

                    </table>
                    <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={usersData.length}
            onChange={handlePageChange}
            style={{ textAlign: 'center', marginTop: '20px' }}
            itemRender={(page, type, originalElement) => {
                if (type === 'page') {
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            style={{
                                //background:darkMode?'black':'inherit',
                                color: darkMode ? 'white ' : 'inherit'
                            }}
                        >
                            {page}
                        </a>
                    );
                }
                return originalElement;
            }}
        />
                    <Modal
                title="Delete User"
                visible={isModalVisible}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="many" type="primary" onClick={handleMany} className='bg-blue-500'>
                    Many
                </Button>,
                <Button key="delete" type="primary" danger onClick={handleDelete}>
                    Delete
                </Button>,
                ]}
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete only this user?</p>
            </Modal>
            <Modal
                title="Mass Delete Users"
                visible={isMassDeleteModalVisible}
                onOk={handleMassDelete}
                onCancel={() =>{setIsMassDeleteModalVisible(false);
                    setDisplayDelete(false)}}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete the selected users?</p>
            </Modal>

                    </div>
                </div>
            </div>
      
                </div>
            

        </>
        
     )}
     {(User?.userType === 2 ||User?.userType === 7) && User?.subRegionId=== 1 && User?.regionId===1 && navigate('/sr-west')}
{(User?.userType === 2 ||User?.userType === 7) && User?.subRegionId=== 2 && User?.regionId===1 && navigate('/sr-east')}
{(User?.userType === 2 ||User?.userType === 7)&& User?.subRegionId=== 3 && User?.regionId===1 && navigate('/sr-north')}
{(User?.userType === 2 ||User?.userType === 7)&& User?.subRegionId=== 4 && User?.regionId===1 && navigate('/sr-south')}
{(User?.userType === 5  || User?.userType === 6 || User?.userType === 3) &&  navigate(`/nation/${User.nationality}`)}
{!(User?.userType === 8  ||(User?.userType === 6 ) ||(User?.userType === 3 )||(User?.userType === 5 )||(User?.userType === 7 )|| (User?.userType === 2 && User?.regionId === 1)) && (
    navigate('/Association')
//      <div className="min-h-screen flex flex-col justify-center items-center px-4">
//      <div className="text-center max-w-lg">
//        <FontAwesomeIcon icon={faLock} size="6x" className="text-red-600 mb-4" />
//        <h1 className="text-3xl font-bold mb-2">Not Enough Permissions</h1>
//        <p className="mb-6">
//          Please contact Superiors to confirm your Authorizations Limits
//        </p>
       
//      </div>
//    </div>
)}
 </>
 
);
};

export default MainPage;
