import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import LikeButton from './likes';
import RateButton from "./rating";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FaRegShareFromSquare } from "react-icons/fa6";
import {  Input, Modal, Pagination,  notification } from 'antd';
import User from "../images/conference.jpg";
import { FadeLoader } from "react-spinners";
import { FcDeleteDatabase } from "react-icons/fc";

import { useDebounce } from "use-debounce";
interface User {
  userType: number;
  // Add other properties if needed
}

const SummitType: React.FC = () => {
  const navigate = useNavigate();
  const [summitsData, setSummitsData] = useState<any[]>([]);
  const [filteredSummitsData, setFilteredSummitsData] = useState<any[]>([]);
  const [nationList, setNationList] = useState<any[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activityIdToDelete, setActivityIdToDelete] = useState(null);
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User : null;
  const [loading, setLoading] = useState(true);
 const darkMode=useDarkMode()
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 20000); // 20000 milliseconds = 20 seconds

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  const getAllActivities = async () => {
    try {
      const apiUrl = 'https://api.upfafrica.org/admin/activity/list';
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "query": {
             "isDeleted": false,
          },
          "options": {
              "select": [
                  "id",
                  "name",
                  "coverImage",
                  "host",
                  "date",
                  "theme",
                  "status",
                  "nationId",
                  "isDeleted"
              ],
              "paginate": 3000
          },
          "isCountOnly": false
        }),
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }

      const userData = await response.json();
      if (Array.isArray(userData.data.data)) {
        setSummitsData(userData?.data?.data?.filter((item: any) => { 
            if (item?.status==='Approved') {
                return true
            } else {
                return false;
            }
          }))
        }
    

      const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "query": {
            "isDeleted": false
          },
          "options": {
            "select": [
              "id",
              "name",
            ],
            "paginate": 666
          },
          "isCountOnly": false
        }),
      });

      if (!nationResponse.ok) {
        console.error('Nation request failed:', nationResponse.status, nationResponse.statusText);
        throw new Error('Nation request failed.');
      }

      const nationData = await nationResponse.json();
      setNationList(nationData.data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getAllActivities();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    function stripTime(date: any) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const currentDateOnly = stripTime(currentDate);

    const filteredData = summitsData.filter(summit => {
      const dbDate = new Date(summit.date);
      const dbDateOnly = stripTime(dbDate);

      if (statusFilter === 'Current Events') {
        return dbDateOnly.getTime() === currentDateOnly.getTime();
      } else if (statusFilter === 'Past Events') {
        return dbDateOnly.getTime() < currentDateOnly.getTime();
      } else if (statusFilter === 'Future Events') {
        return dbDateOnly.getTime() > currentDateOnly.getTime();
      } else {
        return true;
      }
    });

    setFilteredSummitsData(filteredData);
    
  }, [statusFilter, summitsData]);

  const deleteActivity = async (id: any) => {
    try {
      const apiUrl = `https://api.upfafrica.org/admin/activity/softDelete/${id}`;
      const token = localStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }

      const responseData = await response.json();
      console.log('Activity deleted successfully:', responseData);

      getAllActivities();

      notification.open({
        message: 'Activity Deleted',
        description: 'The activity has been deleted successfully.',
        duration: 3, // Duration in seconds
      });

      return responseData;
    } catch (error) {
      console.error('Error:', error);

      notification.error({
        message: 'Error Deleting activity',
        description: 'An error occurred while deleting the activity.',
        duration: 3,
      });

      return error;
    }
  };

  const showModal = (id: any) => {
    setActivityIdToDelete(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteActivity(activityIdToDelete);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };
 
  if (loading && filteredSummitsData.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    );
  }
  if (summitsData.length===0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} className="flex flex-col">
          <button
          onClick={() => navigate('/add-event')}
          className="containerr w-2/12 h-10 mr-3 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue"
        >
          + Add
        </button>
          <FcDeleteDatabase color="#367fd6" className='w-48 h-48' />
          <p className="text-2xl font-semibold">No data Found/Check your Internet Connection</p>
        </div>
    );
  }
  const getNationNameById = (id: any) => {
    const nation = nationList.find(n => n.id === id);
    return nation ? nation.name : '';
  };
  const sortedSummitsData = filteredSummitsData.sort((a: any, b: any) => {
    const aDate = new Date(a.date).getTime();
    const bDate = new Date(b.date).getTime();
    return aDate - bDate;
  });
  
  const futureSummits = sortedSummitsData.filter((summit: any) => {
    const summitDate = new Date(summit.date);
    const currentDate = new Date();
    return summitDate.setHours(0, 0, 0, 0) > currentDate.setHours(0, 0, 0, 0);
  });
  
  const presentSummits = sortedSummitsData.filter((summit: any) => {
    const summitDate = new Date(summit.date);
    const currentDate = new Date();
    return summitDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0);
  });
  
  const pastSummits = sortedSummitsData.filter((summit: any) => {
    const summitDate = new Date(summit.date);
    const currentDate = new Date();
    return summitDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0);
  });
  const pastSummitCount=pastSummits.length
  const firstPastSummit=pastSummitCount-9
  const futureSummitCount=futureSummits.length
  const firstFutureSummit=futureSummitCount-6
  const presentSummitCount=presentSummits.length
  const firstPresentSummit=presentSummitCount-3
  console.log("pastSummits",pastSummits)
  return (
    < div className="grid grid-rows">
   {futureSummits.length>0 &&(  <div >
        <h2 className="text-2xl font-bold border-b-4 border-gray-400 mt-5 w-64 mb-3 text-red-500">Future Events</h2>
        <button
            onClick={() => navigate('/add-event')}
            className="w-2/12 h-10 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr"
          >
            + Add
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 ">
      {futureSummits.slice(firstFutureSummit,futureSummitCount).map((summit: any, id: number) => {
        return (
          <div key={id} className="flex flex-col containerr justify-between bg-white border-2 w-80 rounded-md shadow-lg">
            <div>
              <div className="">
                <img
                  src={ `${summit?.coverImage ? `https://fm.upfafrica.org${summit.coverImage}` : '/default-profile-image.png'}`}
                  alt="Profile"
                  className={`h-48 object-center w-80 mb-4 ${darkMode ? 'bg-slate-300' : ''}`}
                />
              </div>
              <h2 className="text-xl font-semibold text-red-500">
                Future Event
              </h2>
              <div className="flex flex-row justify-between p-2">
                <div className="flex flex-col">
                  <div>
                    <Link to={`/Summits/${summit?.id}`}>
                      <p className="font-semibold">{summit?.name || 'No Name'}</p>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="containerrr rounded-lg px-4 text-center text-gray-800 font-semibold">
                    {summit.nationId ? getNationNameById(summit.nationId) : 'No Nation'}
                  </div>
                </div>
              </div>
              <div className="p-2">
                <p className="font-semibold">Theme:</p>
                <p>{summit.theme}</p>
                <div className="bg-red-200">
                  <p className="text-red-600 font-semibold text-xl">(Coming Soon)</p>
                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="font-semibold mt-2">Rating:</p>
                      <RateButton />
                    </div>
                    <div>
                      <LikeButton />
                      <FaRegShareFromSquare className="mt-4 w-6 h-6" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
      </div>)}
     {presentSummits.length>0 &&( <div >
        <h2 className="text-2xl font-bold border-b-4 border-gray-400 mt-5  mb-2 w-60 text-green-500">Present Events</h2>
        <button
            onClick={() => navigate('/add-event')}
            className="w-2/12 h-10 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr"
          >
            + Add
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 ">
       {presentSummits.slice(firstPresentSummit,presentSummitCount).map((summit: any, id: number) => {
        return (
          <div key={id} className="flex flex-col containerr justify-between bg-white border-2 w-80 rounded-md shadow-lg">
            <div>
              <div className="">
                <img
                  src={ `${summit?.coverImage ? `https://fm.upfafrica.org${summit.coverImage}` : '/default-profile-image.png'}`}
                  alt="Profile"
                  className={`h-48 object-center w-80 mb-4 ${darkMode ? 'bg-slate-300' : ''}`}
                />
              </div>
              <h2 className="text-xl font-semibold text-green-500">
              Ongoing Event
              </h2>
              <div className="flex flex-row justify-between p-2">
                <div className="flex flex-col">
                  <div>
                    <Link to={`/Summits/${summit?.id}`}>
                      <p className="font-semibold">{summit?.name || 'No Name'}</p>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="containerrr rounded-lg px-4 text-center text-gray-800 font-semibold">
                    {summit.nationId ? getNationNameById(summit.nationId) : 'No Nation'}
                  </div>
                </div>
              </div>
              <div className="p-2">
                <p className="font-semibold">Theme:</p>
                <p>{summit.theme}</p>
                <div className="bg-red-200">
                  <p className="text-red-600 font-semibold text-xl">(Coming Soon)</p>
                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="font-semibold mt-2">Rating:</p>
                      <RateButton />
                    </div>
                    <div>
                      <LikeButton />
                      <FaRegShareFromSquare className="mt-4 w-6 h-6" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
      </div>)}
      {pastSummits.length>0 &&(
      <div >
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold border-b-4 border-gray-400 mt-5 w-64 mb-3 text-yellow-500">Past Events</h2>
          <button
            onClick={() => navigate('/add-event')}
            className="w-2/12 h-10 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr"
          >
            + Add
          </button>
        </div>
       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 ">
       {pastSummits.slice(firstPastSummit,pastSummitCount).map((summit: any, id: number) => {
        return (
          <div key={id} className="flex flex-col containerr justify-between bg-white border-2 w-80 rounded-md shadow-lg">
            <div>
              <div className="">
                <img
                  src={ `${summit?.coverImage ? `https://fm.upfafrica.org${summit.coverImage}` : '/default-profile-image.png'}`}
                  alt="Profile"
                  className={`h-48 object-center w-80 mb-4 ${darkMode ? 'bg-slate-300' : ''}`}
                />
              </div>
              <h2 className="text-xl font-semibold text-yellow-500">
               Past Event
              </h2>
              <div className="flex flex-row justify-between p-2">
                <div className="flex flex-col">
                  <div>
                    <Link to={`/Summits/${summit?.id}`}>
                      <p className="font-semibold">{summit?.name || 'No Name'}</p>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="containerrr rounded-lg px-4 text-center text-gray-800 font-semibold">
                    {summit.nationId ? getNationNameById(summit.nationId) : 'No Nation'}
                  </div>
                </div>
              </div>
              <div className="p-2">
                <p className="font-semibold">Theme:</p>
                <p>{summit.theme}</p>
                <div className="bg-red-200">
                  <p className="text-red-600 font-semibold text-xl">(Coming Soon)</p>
                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="font-semibold mt-2">Rating:</p>
                      <RateButton />
                    </div>
                    <div>
                      <LikeButton />
                      <FaRegShareFromSquare className="mt-4 w-6 h-6" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
       </div>)}
      <Modal
        title="Delete User"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={() => setIsModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
      >
        <p>Are you sure you want to delete this activity?</p>
      </Modal>
    </div>

    )   
}  
const SummitsDetail: React.FC = () => {
  const { id } = useParams();
  const [summit, setSummit] = useState<any>(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterValue, setFilterValue] = useState('');
  const [debouncedFilterValue] = useDebounce(filterValue, 200);

  const[participants,setParticipants]=useState<any[]>([])
  const [nationList, setNationList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User : null;



  useEffect(() => {
    const getSummitDetails = async () => {
      console.log(id)
      try {
        const apiUrl = `https://api.upfafrica.org/admin/activity/${id}`;
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          console.error('Request failed:', response.status, response.statusText);
          throw new Error('Request failed.');
        }

        const summitData = await response.json();
        console.log('summitData:', summitData);
        setSummit(summitData.data);
        console.log(summit)
        
        const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              "query": {},
              "options": {
                  "select": [
                      "id",
                      "name",
                  ],
                  "paginate": 166
              },
              "isCountOnly": false
          }),
      });
      const nationData = await nationResponse.json();
      if (nationData?.data) {
          setNationList(nationData.data.data);
      } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
      }
 

        const UserResponse = await fetch('https://api.upfafrica.org/admin/user/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            "query": {
              "isDeleted": false
            },
            "options": {
              "select": [
                "id",
                "name",
                "position",
                "upfPosition",
                "nationality"
              ],
              "paginate": 50000
            },
            "isCountOnly": false
          }),
        });
  
        if (!UserResponse.ok) {
          console.error('user request failed:', UserResponse.status, UserResponse.statusText);
          throw new Error('user request failed.');
        }
  
        const UserData = await UserResponse.json();
        if (UserData?.data?.data) {
          const filteredData = UserData.data.data.filter((item: any) => {
            return (
              (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
            );
          });
  
          setUserList(filteredData);
          if (filteredData.length > 0 && summit && Array.isArray(summit.participantsId)) {
            const participant = summit.participantsId.map((id: any) => {
              const user = filteredData.find((user:any) => user.id === id);
              return user ? user : null;
            }).filter((name: any) => name !== null) as string[]; // Remove null values and cast to string[]
  
            setParticipants(participant);
          }
        } else {
          setUserList([]);
        setParticipants([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    if (id) {
      getSummitDetails();
    }
  }, [id, debouncedFilterValue,summit, userList]);
  // useEffect(() => {
    
  // }, [summit, userList]);
  console.log(participants)
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
  if (!summit) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FadeLoader color="#367fd6" className='w-96 h-96' />
    </div>
    );
  }
 

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setFilterValue(e.target.value);
  setCurrentPage(1); // Reset to the first page when the filter changes
};
const getNationNameById = (id: number) => {
  const nation = nationList.find(nation => nation.id === id);
  return nation ? nation.name : 'Unknown';
};
const paginatedparticipantsData = participants.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
  return (
    <div className="p-10 relative">
      <h1 className="mb-4 font-bold text-3xl" > {summit.name}</h1>
      <div className="relative ">
        <img
          src={ `${summit?.coverImage ? `https://fm.upfafrica.org${summit.coverImage}` : '/default-profile-image.png'}`}
          alt="Summit"
          style={{ height: "50vh" }}
          className="w-full object-center rounded-lg mb-4 bg-gray-600"
        />
      </div>
      
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 mt-10">
        <p className="" style={getTitleStyle()}>Hosted by: {summit.host}</p>
        <p className="" style={getTitleStyle()}>City: {summit.city}</p>
        <p className="mt-4 mb-4"style={getTitleStyle()}>Date: {new Date(summit.date).toLocaleDateString()}</p>
        
      </div>
      <div className=" flex flex-col justify-between ">
      <div>
        <p className="mt-4 mb-4 "style={getTitleStyle()}>Description:</p>
        {summit.description}
       
      </div>
      <p className="mt-4 mb-4 "style={getTitleStyle()}>Participants:</p>
      <div className="bg-white rounded lg containerr p-8">
      <Input
                placeholder="Search by name, position, or nationality"
                value={filterValue}
                onChange={handleInputChange}
                className="mb-4 w-64"
            />
      <table className="w-full">
  <thead className='text-left'>
    <tr>
      <th className="py-2">NO</th>
      <th className="py-2 px-3">Name</th>
      <th className="py-2">Position</th>
      <th className="py-2">Nationality</th>
    </tr>
  </thead>
  <tbody>
    {paginatedparticipantsData.length > 0 ? paginatedparticipantsData.map((summit, index) => (
      <tr key={summit.id}>
        <td className="py-2">{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td className="py-2 px-3">{summit.name}</td>
        <td className="py-2">{summit.position||summit.upfPosition}</td>
        <td className="py-2">{summit.nationality && getNationNameById(summit.nationality)}</td>
      </tr>
    )) : (
      <tr>
        <td  className="py-2 text-center">No participants found</td>
      </tr>
    )}
  </tbody>
</table>
<Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={participants.length}
                onChange={handlePageChange}
                className="mt-4"
            />
      {/* <p className="mt-4 mb-4 " style={getTitleStyle()}>Participants List</p>
      {participants.length > 0 && participants.map(summit => (
        <div key={summit.id}>
          <ol className="mb-2 list-decimal">
           
              <li key={summit.id}>{summit.name}</li>
         
          </ol>
        </div>
      ))} */}
    </div>
    </div>
     
    
    </div>
  );
};

export { SummitType, SummitsDetail };
