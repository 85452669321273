import React, { createContext, useContext, useState } from 'react';

const SummitsContext = createContext();

export const useSummits = () => {
  return useContext(SummitsContext);
};

export const SummitsProvider = ({ children }) => {
  const [summitsCount, setSummitsCount] = useState(0);

  return (
    <SummitsContext.Provider value={{ summitsCount, setSummitsCount }}>
      {children}
    </SummitsContext.Provider>
  );
};
